import React, { useState } from "react";

import {
  IconButton,
  Tooltip,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  Input,
} from "@chakra-ui/react";
import { AddIcon, CheckIcon, CloseIcon } from "@chakra-ui/icons";

function AddContent({ onAddContentAction }) {
  const [formOpen, setFormOpen] = useState("");
  const [contentInput, setContentInput] = useState("");

  return (
    <Popover
      placement="right"
      isOpen={formOpen === "add_content"}
      onOpen={() => setFormOpen("add_content")}
      onClose={() => setFormOpen("")}
    >
      <PopoverTrigger>
        <IconButton
          icon={
            <Tooltip
              hasArrow
              label="Adicionar Disciplina"
              bg="white"
              color="black"
              fontSize="md"
            >
              <AddIcon color="white" />
            </Tooltip>
          }
          bg="blue.600"
          borderRadius="100px"
          w="40px"
          margin="2px 0px"
        />
      </PopoverTrigger>
      <PopoverContent flexDirection={"row"}>
        <PopoverArrow backgroundColor={"black"} />
        <Input
          type="text"
          placeholder="Digite o nome da disciplina"
          onChange={(ev) => setContentInput(ev.target.value)}
          value={contentInput}
        />
        <IconButton
          icon={<CheckIcon />}
          onClick={() => {
            onAddContentAction(contentInput);
            setFormOpen("");
            setContentInput("");
          }}
        />
        <IconButton
          icon={<CloseIcon />}
          onClick={() => {
            setFormOpen("");
            setContentInput("");
          }}
        />
      </PopoverContent>
    </Popover>
  );
}

export default AddContent;
