import { useEffect, useState } from "react";

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  IconButton,
  Input,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Select,
  Tooltip,
} from "@chakra-ui/react";
import {
  AddIcon,
  CheckIcon,
  CloseIcon,
  DeleteIcon,
  EditIcon,
} from "@chakra-ui/icons";

import { api } from "../../../../../../utils/services/api";

import styles from "../../containers/Structure.module.css";

import {
  solutionRegister,
  solutionEdit,
  solutionRemove,
} from "../../../../utils/services/structure";

function SolutionsManager({ userInputs, projectInputs }) {
  const [formOpen, setFormOpen] = useState("");
  const [solutionInputs, setSolutionInputs] = useState({
    selected_name: "",
    name_input: "",
  });
  const [solutionsData, setSolutionsData] = useState([]);
  const [updateSolutions, setUpdateSolutions] = useState(0);
  const [warningMessageF, setWarningMessageF] = useState(false);
  const [warningMessageS, setWarningMessageS] = useState(false);

  function onSolutionSelectedChange(ev) {
    const value = ev.target.value;
    setSolutionInputs((prevInputs) => ({
      ...prevInputs,
      selected_name: value,
    }));
    setUpdateSolutions((prev) => prev + 1);
  }

  function onSolutionInputsChange(ev) {
    const { name, value } = ev.target;
    setSolutionInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
  }

  function onSolutionAction(action) {
    const options = {
      register: () => {
        if (solutionInputs["name_input"] === "") {
          setWarningMessageF(true);
        } else if (
          solutionsData.some(
            (solution) => solution === solutionInputs["name_input"],
          )
        ) {
          setWarningMessageS(true);
        } else {
          solutionRegister([userInputs, projectInputs, solutionInputs]).then(
            () => {
              setUpdateSolutions((prev) => prev + 1);
            },
          );
          setFormOpen("");
          setSolutionInputs((prevInputs) => ({
            ...prevInputs,
            selected_name: solutionInputs["name_input"],
          }));
          setSolutionInputs((prevInputs) => ({
            ...prevInputs,
            name_input: "",
          }));
        }
      },
      edit: () => {
        if (solutionInputs["name_input"] === "") {
          setWarningMessageF(true);
        } else if (
          solutionsData.some(
            (solution) => solution === solutionInputs["name_input"],
          )
        ) {
          setWarningMessageS(true);
        } else {
          solutionEdit([userInputs, projectInputs, solutionInputs]).then(() => {
            setUpdateSolutions((prev) => prev + 1);
          });
          setFormOpen("");
          setSolutionInputs((prevInputs) => ({
            ...prevInputs,
            selected_name: solutionInputs["name_input"],
          }));
          setSolutionInputs((prevInputs) => ({
            ...prevInputs,
            name_input: "",
          }));
        }
      },
      remove: () => {
        solutionRemove([userInputs, projectInputs, solutionInputs]).then(() => {
          setUpdateSolutions((prev) => prev + 1);
        });
        setSolutionInputs((prevInputs) => ({
          ...prevInputs,
          selected_name: solutionsData[0],
        }));
      },
    };
    for (const [key, value] of Object.entries(options)) {
      if (action === key) {
        value();
      }
    }
  }

  useEffect(() => {
    if (projectInputs["selected_name"] !== "") {
      // setIsLoading(true)
      api.post("/solution", [userInputs, projectInputs]).then((response) => {
        setSolutionsData(response["data"]);
      });
      // .finally(() => {
      //     setIsLoading(false);
      // })
    }
  }, [updateSolutions, projectInputs["selected_name"]]);

  return (
    <>
      <div className={styles.containerRow}>
        <div className={styles.step}>
          <div className={styles.secondTitle}>SOLUÇÃO:</div>
          <Select
            name="column"
            onChange={onSolutionSelectedChange}
            variant="outline"
            w="150px"
            size="xs"
            fontSize="md"
            value={solutionInputs["selected_name"]}
          >
            {solutionsData.map((solution, i) => (
              <option key={i} value={solution}>
                {" "}
                {solution}{" "}
              </option>
            ))}
          </Select>
          <Popover
            placement="bottom"
            isOpen={formOpen === "register"}
            onOpen={() => setFormOpen("register")}
            onClose={() => setFormOpen("")}
          >
            <PopoverTrigger>
              <IconButton
                icon={
                  <Tooltip
                    hasArrow
                    label="Criar"
                    bg="gray"
                    color="black"
                    fontSize="md"
                  >
                    <AddIcon />
                  </Tooltip>
                }
                size="sm"
                borderWidth="sm"
                borderRadius="none"
                borderColor="border"
                variant="solid"
                colorScheme="blue"
              />
            </PopoverTrigger>
            <PopoverContent flexDirection={"row"}>
              <PopoverArrow backgroundColor={"black"} />
              <Input
                name="name_input"
                type="text"
                placeholder="Digite o nome da solução"
                onChange={onSolutionInputsChange}
                value={solutionInputs["name_input"]}
              />
              <IconButton
                icon={<CheckIcon />}
                onClick={() => onSolutionAction(formOpen)}
              />
              <IconButton
                icon={<CloseIcon />}
                onClick={() => {
                  setFormOpen("");
                  updateSolutionInputs("name_input", "");
                }}
              />
            </PopoverContent>
          </Popover>
          <Popover
            placement="bottom"
            isOpen={formOpen === "edit"}
            onOpen={() => setFormOpen("edit")}
            onClose={() => setFormOpen("")}
          >
            <PopoverTrigger>
              <IconButton
                icon={
                  <Tooltip
                    hasArrow
                    label="Editar"
                    bg="gray"
                    color="black"
                    fontSize="md"
                  >
                    <EditIcon />
                  </Tooltip>
                }
                size="sm"
                borderWidth="sm"
                borderRadius="none"
                borderColor="border"
                variant="solid"
                colorScheme="blue"
              />
            </PopoverTrigger>
            <PopoverContent flexDirection={"row"}>
              <PopoverArrow backgroundColor={"black"} />
              <Input
                name="name_input"
                type="text"
                placeholder="Digite o nome da solução"
                onChange={onSolutionInputsChange}
                value={solutionInputs["name_input"]}
              />
              <IconButton
                icon={<CheckIcon />}
                onClick={() => onSolutionAction(formOpen)}
              />
              <IconButton
                icon={<CloseIcon />}
                onClick={() => {
                  setFormOpen("");
                  updateSolutionInputs("name_input", "");
                }}
              />
            </PopoverContent>
          </Popover>
          <IconButton
            icon={
              <Tooltip
                hasArrow
                label="Remover"
                bg="gray"
                color="black"
                fontSize="md"
              >
                <DeleteIcon />
              </Tooltip>
            }
            size="sm"
            borderWidth="sm"
            borderRadius="none"
            borderColor="border"
            variant="solid"
            colorScheme="blue"
            onClick={() => onSolutionAction("remove")}
          />
        </div>
        <div className={styles.step}>
          <Button
            name="fill"
            width="100px"
            // onClick={}
            colorScheme="blue"
            size="sm"
            fontSize="md"
          >
            Preencher
          </Button>
        </div>
      </div>
      {warningMessageF && (
        <AlertDialog isOpen={warningMessageF}>
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader>
                A solução não possui um nome
              </AlertDialogHeader>
              <AlertDialogCloseButton
                onClick={() => setWarningMessageF(false)}
              />
              <AlertDialogBody>
                Insira um nome para a solução para prosseguir.
              </AlertDialogBody>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      )}
      {warningMessageS && (
        <AlertDialog isOpen={warningMessageS}>
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader>Solução existente</AlertDialogHeader>
              <AlertDialogCloseButton
                onClick={() => setWarningMessageS(false)}
              />
              <AlertDialogBody>
                Essa solução já existe, insira outro nome.
              </AlertDialogBody>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      )}
    </>
  );
}

export default SolutionsManager;
