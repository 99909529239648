import { api } from "../../../../utils/services/api";

export function composingList(data) {
  return api.post("/composing", data);
}

export function composingRegister(data) {
  return api.post("/composing/register", data);
}

export function composingDuplicate(data) {
  return api.post("/composing/duplicate", data);
}

export function composingEdit(data) {
  return api.post("/composing/edit", data);
}

export function composingRemove(data) {
  return api.post("/composing/remove", data);
}

export function composingSave(data) {
  return api.post("/composing/save", data);
}
