import React, { useState } from "react";

import {
  Select,
  Modal,
  ModalOverlay,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalBody,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";

function ConfigInstance({
  ifcData,
  composing,
  updateComposingInputs,
  isOpen,
  content,
  category,
  instance,
  settingsType,
  setInstanceSettingsForm,
  categoryIfcTypeExist,
  instanceGroupExist,
  instanceParameterExist,
}) {
  function onChangeInstanceSettingsGroup(
    content,
    category,
    instance,
    settingsType,
    group
  ) {
    const newcomposing = { ...composing };
    newcomposing[content][category]["instances"][instance][settingsType][
      "group"
    ] = group;
    if (group !== "Material") {
      newcomposing[content][category]["instances"][instance][settingsType][
        "parameter"
      ] = Object.keys(
        ifcData[composing[content][category]["ifcType"]][0][
          composing[content][category]["instances"][instance][settingsType][
            "group"
          ]
        ]
      )[0];
    } else {
      newcomposing[content][category]["instances"][instance][settingsType][
        "parameter"
      ] = "";
    }
    updateComposingInputs("composing", newcomposing);
  }

  function onChangeInstanceSettingsParameter(
    content,
    category,
    instance,
    settingsType,
    parameter
  ) {
    const newcomposing = { ...composing };
    newcomposing[content][category]["instances"][instance][settingsType][
      "parameter"
    ] = parameter;
    updateComposingInputs("composing", newcomposing);
  }

  return (
    content !== "" && (
      <Modal
        isOpen={isOpen}
        onClose={() =>
          setInstanceSettingsForm((prevInputs) => ({
            ...prevInputs,
            ["isOpen"]: false,
          }))
        }
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader bg="blue.600" color="white">
            {settingsType === "discretize"
              ? "Configurações de Discretização"
              : "Configurações de Subdivisão"}
          </ModalHeader>
          <ModalCloseButton color="white" />
          <ModalBody verticalAlign="center" pb={1}>
            <FormControl
              w="1000px"
              display="flex"
              flexDirection="row"
              alignItems="flex-end"
              margin="10px 0px"
            >
              <FormLabel>Grupo do Parâmetro:</FormLabel>
              <Select
                w="250px"
                value={
                  composing[content][category]["instances"][instance][
                    settingsType
                  ]["group"]
                }
                onChange={(ev) =>
                  onChangeInstanceSettingsGroup(
                    content,
                    category,
                    instance,
                    settingsType,
                    ev.target.value
                  )
                }
                disabled={
                  !categoryIfcTypeExist(composing[content][category]["ifcType"])
                }
              >
                {categoryIfcTypeExist(
                  composing[content][category]["ifcType"]
                ) &&
                  (instanceGroupExist(
                    content,
                    category,
                    composing[content][category]["instances"][instance][
                      settingsType
                    ]["group"]
                  ) ||
                  composing[content][category]["instances"][instance][
                    settingsType
                  ]["group"] === "Material" ? (
                    <>
                      <optgroup label="Opções Personalizadas:">
                        <option key="Material">Material</option>
                      </optgroup>
                      <optgroup label="Opções do Modelo:">
                        {Object.keys(
                          ifcData[composing[content][category]["ifcType"]][0]
                        ).map(
                          (group) =>
                            group !== "Material" && (
                              <option key={group} value={group}>
                                {group}
                              </option>
                            )
                        )}
                      </optgroup>
                    </>
                  ) : (
                    <>
                      <option
                        key={
                          composing[content][category]["instances"][instance][
                            settingsType
                          ]["group"]
                        }
                        value={
                          composing[content][category]["instances"][instance][
                            settingsType
                          ]["group"]
                        }
                      >
                        {
                          composing[content][category]["instances"][instance][
                            settingsType
                          ]["group"]
                        }{" "}
                        não foi encontrado
                      </option>
                      <optgroup label="Opções Personalizadas:">
                        <option key="Material">Material</option>
                      </optgroup>
                      <optgroup label="Opções do Modelo:">
                        {Object.keys(
                          ifcData[composing[content][category]["ifcType"]][0]
                        ).map(
                          (group) =>
                            group !== "Material" && (
                              <option key={group} value={group}>
                                {group}
                              </option>
                            )
                        )}
                      </optgroup>
                    </>
                  ))}
              </Select>
            </FormControl>
            <FormControl
              display="flex"
              flexDirection="row"
              alignItems="flex-end"
              margin="10px 0px"
            >
              <FormLabel>Nome do Parâmetro:</FormLabel>
              <Select
                w="250px"
                value={
                  composing[content][category]["instances"][instance][
                    settingsType
                  ]["parameter"]
                }
                onChange={(ev) =>
                  onChangeInstanceSettingsParameter(
                    content,
                    category,
                    instance,
                    settingsType,
                    ev.target.value
                  )
                }
                disabled={
                  !categoryIfcTypeExist(
                    composing[content][category]["ifcType"]
                  ) ||
                  composing[content][category]["instances"][instance][
                    settingsType
                  ]["group"] === "Material" ||
                  !instanceGroupExist(
                    content,
                    category,
                    composing[content][category]["instances"][instance][
                      settingsType
                    ]["group"]
                  )
                }
              >
                {categoryIfcTypeExist(
                  composing[content][category]["ifcType"]
                ) &&
                  instanceGroupExist(
                    content,
                    category,
                    composing[content][category]["instances"][instance][
                      settingsType
                    ]["group"]
                  ) &&
                  (instanceParameterExist(
                    content,
                    category,
                    composing[content][category]["instances"][instance][
                      settingsType
                    ]["group"],
                    composing[content][category]["instances"][instance][
                      settingsType
                    ]["parameter"]
                  ) ? (
                    <optgroup label="Parâmetros do Grupo:">
                      {Object.keys(
                        ifcData[composing[content][category]["ifcType"]][0][
                          composing[content][category]["instances"][instance][
                            settingsType
                          ]["group"]
                        ]
                      ).map((parameter) => (
                        <option key={parameter} value={parameter}>
                          {parameter}
                        </option>
                      ))}
                    </optgroup>
                  ) : composing[content][category]["instances"][instance][
                      settingsType
                    ]["group"] !== "Material" ? (
                    <>
                      <option
                        key={
                          composing[content][category]["instances"][instance][
                            settingsType
                          ]["parameter"]
                        }
                        value={
                          composing[content][category]["instances"][instance][
                            settingsType
                          ]["parameter"]
                        }
                      >
                        {
                          composing[content][category]["instances"][instance][
                            settingsType
                          ]["parameter"]
                        }{" "}
                        não foi encontrado
                      </option>
                      <optgroup label="Parâmetros do Grupo:">
                        {Object.keys(
                          ifcData[composing[content][category]["ifcType"]][0][
                            composing[content][category]["instances"][instance][
                              settingsType
                            ]["group"]
                          ]
                        ).map((parameter) => (
                          <option key={parameter} value={parameter}>
                            {parameter}
                          </option>
                        ))}
                      </optgroup>
                    </>
                  ) : (
                    <></>
                  ))}
              </Select>
            </FormControl>
          </ModalBody>
        </ModalContent>
      </Modal>
    )
  );
}

export default ConfigInstance;
