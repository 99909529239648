import { useState, createContext } from "react";

import { IconButton } from "@chakra-ui/react";
import { ArrowRightIcon } from "@chakra-ui/icons";
import { FiMenu } from "react-icons/fi";

import styles from "./Geotechnics.module.css";

import CamadaDefinir from "../components/geotechnics/CamadaDefinir";
import CamadaEntradas from "../components/geotechnics/CamadaEntradas";
import CamadaAcoes from "../components/geotechnics/CamadaAcoes";
import MetodoDefinir from "../components/geotechnics/MetodoDefinir";
import CalculoDefinir from "../components/geotechnics/CalculoDefinir";
import CalculoEntradas1 from "../components/geotechnics/CalculoEntradas1";
import CalculoEntradas2 from "../components/geotechnics/CalculoEntradas2";
import CalculoAcoes from "../components/geotechnics/CalculoAcoes";
import EsforcoDefinir from "../components/geotechnics/EsforcoDefinir";
import ResultadoTabela from "../components/geotechnics/ResultadoTabela";
import SondagemAcoes from "../components/geotechnics/SondagemAcoes";
import Label from "../components/geotechnics/Label";

// criando contexto para compartilhar dados entre componentes
export const LayerContext = createContext({});

function Geotechnics({
  userInputs,
  projectInputs,
  foundationClass,
  investigationInputs,
  updateInvestigationInputs,
  investigationsData,
  setInvestigationsData,
  geotechnicsInputs,
  updateGeotechnicsInputs,
  structureInputs,
  geotechnicsData,
  setGeotechnicsData,
  updateGeotechnics,
  setUpdateGeotechnics,
  layerInputs,
  updateLayerInputs,
  methodsData,
  onOpen,
  isLoading,
}) {
  const [soilClass, setSoilClass] = useState("areia");

  function onProjectManagerOpen() {
    updateInvestigationInputs("selected_name", "");
    onOpen();
  }

  return (
    <LayerContext.Provider
      value={{
        layerInputs,
        updateLayerInputs,
      }}
    >
      <div className={styles.grid}>
        <header className={styles.header}>
          <strong>
            {" "}
            <Label text="GEOTECNIA" />{" "}
          </strong>
          <div className={styles.stepsContainer}>
            <div className={styles.steps}>
              <div className={styles.step}>
                <Label text="SONDAGEM:" />
              </div>
              <div className={styles.step}>
                <CamadaDefinir
                  soilClass={soilClass}
                  setSoilClass={setSoilClass}
                  updateLayerInputs={updateLayerInputs}
                />
              </div>
              <div className={styles.step}>
                <CamadaEntradas
                  soilClass={soilClass}
                  updateLayerInputs={updateLayerInputs}
                />
              </div>
              <div className={styles.step}>
                <CamadaAcoes
                  userInputs={userInputs}
                  projectInputs={projectInputs}
                  investigationInputs={investigationInputs}
                  layerInputs={layerInputs}
                  setUpdateGeotechnics={setUpdateGeotechnics}
                />
              </div>
            </div>
            <div className={styles.steps}>
              <MetodoDefinir
                foundationClass={foundationClass}
                geotechnicsInputs={geotechnicsInputs}
                updateGeotechnicsInputs={updateGeotechnicsInputs}
                methodsData={methodsData}
              />
            </div>
            <div className={styles.steps}>
              <div className={styles.step}>
                <CalculoDefinir
                  foundationClass={foundationClass}
                  updateGeotechnicsInputs={updateGeotechnicsInputs}
                />
              </div>
              <div className={styles.step}>
                <CalculoEntradas1
                  geotechnicsInputs={geotechnicsInputs}
                  updateGeotechnicsInputs={updateGeotechnicsInputs}
                />
              </div>
              <div className={styles.step}>
                <CalculoEntradas2
                  foundationClass={foundationClass}
                  updateGeotechnicsInputs={updateGeotechnicsInputs}
                  geotechnicsData={geotechnicsData}
                />
              </div>
              <div className={styles.step}>
                <CalculoAcoes
                  foundationClass={foundationClass}
                  layerInputs={layerInputs}
                  geotechnicsInputs={geotechnicsInputs}
                  geotechnicsData={geotechnicsData}
                  setGeotechnicsData={setGeotechnicsData}
                  methodsData={methodsData}
                />
              </div>
            </div>
          </div>
        </header>
        <nav>
          <EsforcoDefinir updateGeotechnicsInputs={updateGeotechnicsInputs} />
        </nav>
        <section>
          <ResultadoTabela
            geotechnicsData={geotechnicsData}
            structureInputs={structureInputs}
            layerInputs={layerInputs}
            updateLayerInputs={updateLayerInputs}
            isLoading={isLoading}
          />
        </section>
        <footer className={styles.footer}>
          <div className={styles.leftFooter}>
            <IconButton
              colorScheme="blue"
              aria-label="Abrir Gerenciador de Projetos"
              icon={<FiMenu size={20} />}
              onClick={onProjectManagerOpen}
            />
          </div>
          <div className={styles.rightFooter}>
            <SondagemAcoes
              userInputs={userInputs}
              projectInputs={projectInputs}
              investigationInputs={investigationInputs}
              updateInvestigationInputs={updateInvestigationInputs}
              investigationsData={investigationsData}
              setInvestigationsData={setInvestigationsData}
              setUpdateGeotechnics={setUpdateGeotechnics}
            />
          </div>
        </footer>
      </div>
    </LayerContext.Provider>
  );
}

export default Geotechnics;
