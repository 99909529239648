import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";

import Home from "./pages/Home";

function Main({ userInputs }) {
  const navigate = useNavigate();

  return (
    <>
      {userInputs["id"] !== null ? (
        <Home userInputs={userInputs} />
      ) : (
        navigate("/")
      )}
    </>
  );
}

export default Main;
