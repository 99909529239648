import { Select } from "@chakra-ui/react";

import styles from "../../containers/Geotechnics.module.css";

import Label from "./Label";

function MetodoDefinir({
  foundationClass,
  geotechnicsInputs,
  updateGeotechnicsInputs,
  methodsData,
}) {
  return (
    <>
      <div className={styles.step}>
        <Label text="MÉTODOS:" />
      </div>
      <Select
        name="metodo"
        onChange={(ev) => updateGeotechnicsInputs("metodo", ev.target.value)}
        variant="outline"
        w="200px"
        size="xs"
        fontSize="md"
        value={geotechnicsInputs["metodo"]}
      >
        {Object.keys(
          foundationClass === "tubuloes"
            ? methodsData["estacas"]
            : methodsData[foundationClass]
        ).map((method) => (
          <option key={method}>{method}</option>
        ))}
      </Select>
    </>
  );
}

export default MetodoDefinir;
