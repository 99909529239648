import { useState } from "react";
import styles from "../../../containers/Calculators.module.css";

import Select from "../../Select";
import InputNumber from "../../InputNumber";
import Output from "../../Output";

import { concreteBeamBending } from "../../../../../utils/services/calculators";

function Beam({}) {
  const [inputs, setInputs] = useState({
    fck: 20,
    fyk: 25,
    md: 0,
    b: 0,
    h: 0,
    dl: 0,
  });
  const [outputs, setOutputs] = useState({
    ln: 0,
    domain: 0,
    ast: 0,
    asc: 0,
  });

  function updateInputs(name, value) {
    setInputs((prevInputs) => ({ ...prevInputs, [name]: parseFloat(value) }));
    setOutputs({
      ln: 0,
      domain: 0,
      ast: 0,
      asc: 0,
    });
  }

  function onCalculate() {
    concreteBeamBending(inputs).then((response) => {
      setOutputs(response["data"]);
    });
  }

  return (
    <>
      <div className={styles.section}>
        <h1>Flexão simples para seção retangular</h1>
        <div className={styles.separator}></div>
        <div className={styles.calculator}>
          <div className={styles.inputs}>
            <Select
              text="fck"
              elements={[20, 25, 30, 35, 40, 45, 50]}
              unit="Mpa"
              onChange={(e) => updateInputs("fck", e.target.value)}
            />
            <Select
              text="fyk"
              elements={[25, 50, 60]}
              onChange={(e) => updateInputs("fyk", e.target.value)}
            />
            <InputNumber
              text="Md"
              unit="tfm"
              onChange={(e) => updateInputs("md", e.target.value)}
            />
            <InputNumber
              text="b"
              unit="cm"
              onChange={(e) => updateInputs("b", e.target.value)}
            />
            <InputNumber
              text="h"
              unit="cm"
              onChange={(e) => updateInputs("h", e.target.value)}
            />
            <InputNumber
              text="dl"
              unit="cm"
              onChange={(e) => updateInputs("dl", e.target.value)}
            />
          </div>
          <div className={styles.buttons}>
            <button className={styles.calculateButton} onClick={onCalculate}>
              CALCULAR
            </button>
          </div>
          <div className={styles.outputs}>
            <Output text="LN" value={outputs["ln"]} unit="cm" />
            <Output text="Domínio" value={outputs["domain"]} />
            <Output text="As" value={outputs["ast"]} unit="cm²" />
            <Output text="As'" value={outputs["asc"]} unit="cm²" />
          </div>
        </div>
      </div>
    </>
  );
}

export default Beam;
