import React, { useEffect, useState } from "react";

import {
  Select,
  Input,
  Modal,
  ModalOverlay,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalBody,
  FormControl,
  FormLabel,
  Button,
} from "@chakra-ui/react";

function ConfigCategory({
  ifcData,
  composing,
  updateComposingInputs,
  categorySettings,
  updateCategorySettings,
  categoryIfcTypeExist,
  categoryFilterGroupExist,
  categoryFilterParameterExist,
}) {
  const [categorySettingsForm, setCategorySettingsForm] = useState({
    categoryIfcType: "",
    categoryFilterGroup: "",
    categoryFilterParameter: "",
    categoryFilterValue: "",
  });

  function updateCategorySettingsForm(name, value) {
    setCategorySettingsForm((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  }

  // Open Category configs form function
  function openCategorySettingsForm() {
    updateCategorySettingsForm(
      "categoryIfcType",
      composing[categorySettings["content"]][categorySettings["category"]][
        "ifcType"
      ]
    );
    updateCategorySettingsForm(
      "categoryFilterGroup",
      composing[categorySettings["content"]][categorySettings["category"]][
        "filterGroup"
      ]
    );
    updateCategorySettingsForm(
      "categoryFilterParameter",
      composing[categorySettings["content"]][categorySettings["category"]][
        "filterParameter"
      ]
    );
    updateCategorySettingsForm(
      "categoryFilterValue",
      composing[categorySettings["content"]][categorySettings["category"]][
        "filterValue"
      ]
    );
  }

  // Change Category configs functions
  function onChangeCategoryIfcType(type) {
    updateCategorySettingsForm("categoryIfcType", type);
    updateCategorySettingsForm("categoryFilterGroup", "-");
    updateCategorySettingsForm("categoryFilterParameter", "");
    updateCategorySettingsForm("categoryFilterValue", "");
  }

  function onChangeCategoryFilterGroup(group) {
    updateCategorySettingsForm("categoryFilterGroup", group);
    if (group === "-") {
      updateCategorySettingsForm("categoryFilterParameter", "");
      updateCategorySettingsForm("categoryFilterValue", "");
    } else if (group !== "Manual") {
      updateCategorySettingsForm(
        "categoryFilterParameter",
        Object.keys(
          ifcData[categorySettingsForm["categoryIfcType"]][0][group]
        )[0]
      );
    }
  }

  function onChangeCategoryFilterParameter(parameter) {
    updateCategorySettingsForm("categoryFilterParameter", parameter);
  }

  function onChangeCategoryFilterValue(parameterValue) {
    updateCategorySettingsForm("categoryFilterValue", parameterValue);
  }

  // Open Category configs form function
  function saveCategorySettingsForm() {
    const newcomposing = { ...composing };
    newcomposing[categorySettings["content"]][categorySettings["category"]][
      "ifcType"
    ] = categorySettingsForm["categoryIfcType"];
    newcomposing[categorySettings["content"]][categorySettings["category"]][
      "filterGroup"
    ] = categorySettingsForm["categoryFilterGroup"];
    newcomposing[categorySettings["content"]][categorySettings["category"]][
      "filterParameter"
    ] = categorySettingsForm["categoryFilterParameter"];
    newcomposing[categorySettings["content"]][categorySettings["category"]][
      "filterValue"
    ] = categorySettingsForm["categoryFilterValue"];
    updateComposingInputs("composing", newcomposing);
    closeCategorySettingsForm();
  }

  // Open Category configs form function
  function closeCategorySettingsForm() {
    updateCategorySettings("content", "");
    updateCategorySettings("category", "");
    updateCategorySettingsForm("categoryIfcType", "");
    updateCategorySettingsForm("categoryFilterGroup", "");
    updateCategorySettingsForm("categoryFilterParameter", "");
    updateCategorySettingsForm("categoryFilterValue", "");
  }

  useEffect(() => {
    if (categorySettings["content"] !== "") {
      openCategorySettingsForm();
    }
  }, [categorySettings["content"]]);

  return (
    categorySettings["content"] !== "" && (
      <Modal
        isOpen={categorySettingsForm["categoryIfcType"] !== ""}
        onClose={closeCategorySettingsForm}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader bg="blue.600" color="white">
            Configurações de Filtro da Categoria
          </ModalHeader>
          <ModalCloseButton color="white" />
          <ModalBody verticalAlign="center" pb={1}>
            <FormControl
              display="flex"
              flexDirection="row"
              alignItems="flex-end"
              margin="10px 0px"
            >
              <FormLabel>Tipo do Elemento:</FormLabel>
              <Select
                w="250px"
                value={categorySettingsForm["categoryIfcType"]}
                onChange={(ev) => onChangeCategoryIfcType(ev.target.value)}
              >
                {categoryIfcTypeExist(
                  categorySettingsForm["categoryIfcType"]
                ) ? (
                  Object.keys(ifcData).map((ifcDataType) => (
                    <option>{ifcDataType}</option>
                  ))
                ) : (
                  <>
                    <option value={categorySettingsForm["categoryIfcType"]}>
                      {categorySettingsForm["categoryIfcType"]} não foi
                      encontrado
                    </option>
                    {Object.keys(ifcData).map((ifcDataType) => (
                      <option>{ifcDataType}</option>
                    ))}
                  </>
                )}
              </Select>
            </FormControl>
            <FormControl
              w="1000px"
              display="flex"
              flexDirection="row"
              alignItems="flex-end"
              margin="10px 0px"
            >
              <FormLabel>Grupo do Parâmetro:</FormLabel>
              <Select
                w="250px"
                value={categorySettingsForm["categoryFilterGroup"]}
                onChange={(ev) => onChangeCategoryFilterGroup(ev.target.value)}
                disabled={
                  !categoryIfcTypeExist(categorySettingsForm["categoryIfcType"])
                }
              >
                <option value="-">-</option>
                {categoryIfcTypeExist(
                  categorySettingsForm["categoryIfcType"]
                ) &&
                  (categoryFilterGroupExist(
                    categorySettingsForm["categoryIfcType"],
                    categorySettingsForm["categoryFilterGroup"]
                  ) || categorySettingsForm["categoryFilterGroup"] === "-" ? (
                    Object.keys(
                      ifcData[categorySettingsForm["categoryIfcType"]][0]
                    ).map(
                      (ifcDataFilterGroup) =>
                        ifcDataFilterGroup !== "Material" && (
                          <option value={ifcDataFilterGroup}>
                            {ifcDataFilterGroup}
                          </option>
                        )
                    )
                  ) : (
                    <>
                      <option
                        value={categorySettingsForm["categoryFilterGroup"]}
                      >
                        {categorySettingsForm["categoryFilterGroup"]} não foi
                        encontrado
                      </option>
                      {Object.keys(
                        ifcData[categorySettingsForm["categoryIfcType"]][0]
                      ).map(
                        (ifcDataFilterGroup) =>
                          ifcDataFilterGroup !== "Material" && (
                            <option value={ifcDataFilterGroup}>
                              {ifcDataFilterGroup}
                            </option>
                          )
                      )}
                    </>
                  ))}
              </Select>
            </FormControl>
            <FormControl
              display="flex"
              flexDirection="row"
              alignItems="flex-end"
              margin="10px 0px"
            >
              <FormLabel>Nome do Parâmetro:</FormLabel>
              {categoryIfcTypeExist(categorySettingsForm["categoryIfcType"]) ? (
                categorySettingsForm["categoryFilterGroup"] !== "-" ? (
                  categoryFilterGroupExist(
                    categorySettingsForm["categoryIfcType"],
                    categorySettingsForm["categoryFilterGroup"]
                  ) ? (
                    <Select
                      w="200px"
                      value={categorySettingsForm["categoryFilterParameter"]}
                      onChange={(ev) =>
                        onChangeCategoryFilterParameter(ev.target.value)
                      }
                      disabled={
                        !categoryIfcTypeExist(
                          categorySettingsForm["categoryIfcType"]
                        ) ||
                        !categoryFilterGroupExist(
                          categorySettingsForm["categoryIfcType"],
                          categorySettingsForm["categoryFilterGroup"]
                        )
                      }
                    >
                      {categoryFilterParameterExist(
                        categorySettingsForm["categoryIfcType"],
                        categorySettingsForm["categoryFilterGroup"],
                        categorySettingsForm["categoryFilterParameter"]
                      ) ? (
                        Object.keys(
                          ifcData[categorySettingsForm["categoryIfcType"]][0][
                            categorySettingsForm["categoryFilterGroup"]
                          ]
                        ).map((parameter) => <option>{parameter}</option>)
                      ) : (
                        <>
                          <option
                            value={
                              categorySettingsForm["categoryFilterParameter"]
                            }
                          >
                            {categorySettingsForm["categoryFilterParameter"]}{" "}
                            não foi encontrado
                          </option>
                          {Object.keys(
                            ifcData[categorySettingsForm["categoryIfcType"]][0][
                              categorySettingsForm["categoryFilterGroup"]
                            ]
                          ).map((parameter) => (
                            <option>{parameter}</option>
                          ))}
                        </>
                      )}
                    </Select>
                  ) : (
                    <Select
                      w="200px"
                      value={categorySettingsForm["categoryFilterParameter"]}
                      disabled={true}
                    ></Select>
                  )
                ) : (
                  <Select
                    w="200px"
                    value={categorySettingsForm["categoryFilterParameter"]}
                    disabled={true}
                  ></Select>
                )
              ) : (
                <Select
                  w="200px"
                  value={categorySettingsForm["categoryFilterParameter"]}
                  disabled={true}
                ></Select>
              )}
            </FormControl>
            <FormControl
              display="flex"
              flexDirection="row"
              alignItems="flex-end"
              margin="10px 0px"
            >
              <FormLabel>Valor do Parâmetro:</FormLabel>
              <Input
                w="150px"
                value={categorySettingsForm["categoryFilterValue"]}
                onChange={(ev) => onChangeCategoryFilterValue(ev.target.value)}
                isDisabled={
                  !categoryIfcTypeExist(
                    categorySettingsForm["categoryIfcType"]
                  ) ||
                  !categoryFilterGroupExist(
                    categorySettingsForm["categoryIfcType"],
                    categorySettingsForm["categoryFilterGroup"]
                  ) ||
                  !categoryFilterParameterExist(
                    categorySettingsForm["categoryIfcType"],
                    categorySettingsForm["categoryFilterGroup"],
                    categorySettingsForm["categoryFilterParameter"]
                  )
                }
              />
            </FormControl>
            <FormControl display="flex" justifyContent="right">
              <Button
                width="100px"
                onClick={saveCategorySettingsForm}
                bgColor="blue.600"
                color="white"
                size="md"
                fontSize="md"
                w="100%"
              >
                Salvar
              </Button>
            </FormControl>
          </ModalBody>
        </ModalContent>
      </Modal>
    )
  );
}

export default ConfigCategory;
