import styles from "./Home.module.css";
import React, { useState } from "react";
import {
  ChakraProvider,
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Heading,
} from "@chakra-ui/react";
import { BsHouse } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

import example from "../utils/data/example.json";

function Home() {
  const navigate = useNavigate();
  const [formOpen, setFormOpen] = useState(false);
  const [concretingInputs, setConcretingInputs] = useState({
    date: "",
    place: "",
    fck: "",
    volume: "",
  });

  return (
    <>
      <div className={styles.bodyComplas}>
        <div className={styles.main}>
          <div className={styles.navbar}>
            <button className={styles.button}>Lançamento</button>
            <button className={styles.button}>Resultados</button>
            <button className={styles.button}>Relatório</button>
          </div>
          <div className={styles.table}>
            <Heading>Gerenciador de Lançamentos</Heading>
            <div className={styles.containerTable}>
              <Accordion allowMultiple>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>Data</Th>
                      <Th>Local</Th>
                      <Th>Fck</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {example["user"]["concretagem"].map(
                      (concretagem, index) => (
                        <AccordionItem key={index} border="none">
                          <AccordionButton as={Tr}>
                            <Td>{concretagem["Data"]}</Td>
                            <Td>{concretagem["Local"]}</Td>
                            <Td>{concretagem["Fck"]}</Td>
                            <AccordionIcon />
                          </AccordionButton>
                          <AccordionPanel p={0}>
                            <Table variant="simple" size="sm">
                              <Thead>
                                <Tr>
                                  <Th>Romaneio</Th>
                                  <Th>Volume</Th>
                                  <Th>Elemento concretado</Th>
                                </Tr>
                              </Thead>
                              <Tbody>
                                {concretagem["Caminhões"].map(
                                  (caminhão, detailIndex) => (
                                    <Tr key={detailIndex}>
                                      <Td>{caminhão["Romaneio"]}</Td>
                                      <Td>{caminhão["Volume"]}</Td>
                                      <Td>{caminhão["Elemento concretado"]}</Td>
                                    </Tr>
                                  )
                                )}
                              </Tbody>
                            </Table>
                          </AccordionPanel>
                        </AccordionItem>
                      )
                    )}
                  </Tbody>
                </Table>
              </Accordion>
              {/* AS COLUNAS DE CADA ROMANEIO TERÁ QUE SER CUSTOMIZÁVEL O USUÁRIO VAI CONFIGURAR QUANTOS CORPOS DE PROVA E A QUAL DIA SE REFERE CADA UM */}
            </div>
          </div>
        </div>
      </div>
      {/* <Flex direction="column" height="100%">
        <Flex direction="row" bg="black" p={4} align="center" height="5%">
          <IconButton
            icon={<BsHouse size={20} color="white" />}
            borderRadius="none"
            onClick={() => navigate("/")}
          />
        </Flex>
        <Flex
          direction="row"
          flex={1}
          justify="center"
          align="center"
          height="95%"
        >
          <div className={styles.contentButtons}>
            <Button
              variant="solid"
              borderRadius="20px"
              bg="black"
              color="white"
              w="500px"
              onClick={() => setFormOpen(true)}
            >
              Lançamento
            </Button>
            <Button
              variant="solid"
              borderRadius="20px"
              bg="black"
              color="white"
              w="500px"
            >
              Resultados
            </Button>
            <Button
              variant="solid"
              borderRadius="20px"
              bg="black"
              color="white"
              w="500px"
            >
              Relatório
            </Button>
          </div>
          <div className={styles.contentTable}>
            <Heading>Gerenciador de Lançamentos</Heading>
            <div className={styles.containerTable}>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>Data</Th>
                    <Th>Local</Th>
                    <Th>Fck</Th>
                    <Th>Volume</Th>
                  </Tr>
                </Thead>
              </Table>
            </div>
          </div>
        </Flex>
      </Flex>
      {formOpen === true && (
        <Modal
          isOpen={formOpen === true}
          onClose={setFormOpen(false)}
          isCentered
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader bg="black" color="white">
              Cadastramento de concretagem
            </ModalHeader>
            <ModalCloseButton color="white" />
            <ModalBody verticalAlign="center">
              <FormControl
                display="flex"
                flexDirection="row"
                alignItems="flex-end"
                margin="10px 0px"
              >
                <FormLabel>Data:</FormLabel>
              </FormControl>
            </ModalBody>
          </ModalContent>
        </Modal>
      )} */}
    </>
  );
}

export default Home;
