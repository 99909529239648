import { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";

import { api } from "../../utils/services/api";

import Home from "./pages/Home";
import ParametersManager from "./pages/ParametersManager";

import parametersMethods from "./utils/data/parametersMethods.json";

function Main({ userInputs }) {
  const navigate = useNavigate();
  const [projectInputs, setProjectInputs] = useState({
    selected_name: "",
    name_input: "",
  });
  const [projectsData, setProjectsData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [updateProjects, setUpdateProjects] = useState(0);

  function updateProjectInputs(key, value) {
    setProjectInputs((prevInputs) => ({ ...prevInputs, [key]: value }));
  }

  useEffect(() => {
    api.post("/project", userInputs).then((response) => {
      setProjectsData(response["data"]);
      const filter = response["data"].filter((name) =>
        name.toLowerCase().includes(searchTerm.toLowerCase()),
      );
      setFilteredProjects(filter);
    });
  }, [updateProjects]);

  const [methodInputs, setMethodInputs] = useState({
    foundation_class: "estacas",
    selected_name: "Aoki-Velloso",
    name_input: "",
    parameters: parametersMethods["estacas"]["Aoki-Velloso"],
  });
  const [methodsData, setMethodsData] = useState(parametersMethods);
  const [updateMethods, setUpdateMethods] = useState(0);

  function updateMethodInputs(name, value) {
    setMethodInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
  }

  useEffect(() => {
    api.post("/method", userInputs).then((response) => {
      setMethodsData((prevMethodsData) => {
        const updatedMethodsData = { ...parametersMethods };

        response["data"].map((foundationClass) => {
          const { estacas, sapatas } = foundationClass;

          if (estacas) {
            updatedMethodsData.estacas = {
              ...updatedMethodsData.estacas,
              ...estacas,
            };
          }

          if (sapatas) {
            updatedMethodsData.sapatas = {
              ...updatedMethodsData.sapatas,
              ...sapatas,
            };
          }
        });

        return updatedMethodsData;
      });
    });
    return;
  }, [updateMethods]);

  return (
    <>
      {userInputs["id"] !== null ? (
        <Routes>
          <Route
            path="/"
            element={
              <Home
                userInputs={userInputs}
                projectInputs={projectInputs}
                updateProjectInputs={updateProjectInputs}
                projectsData={projectsData}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                filteredProjects={filteredProjects}
                setUpdateProjects={setUpdateProjects}
                methodsData={methodsData}
                setUpdateMethods={setUpdateMethods}
              />
            }
          />
          <Route
            path="/parameters-manager"
            element={
              <ParametersManager
                userInputs={userInputs}
                methodInputs={methodInputs}
                setMethodInputs={setMethodInputs}
                updateMethodInputs={updateMethodInputs}
                methodsData={methodsData}
                setUpdateMethods={setUpdateMethods}
              />
            }
          />
        </Routes>
      ) : (
        navigate("/")
      )}
    </>
  );
}

export default Main;
