import React, { useEffect, useState } from "react";
import { Builder } from "xml2js";
import { saveAs } from "file-saver";

import styles from "../../Home.module.css";

import {
  Box,
  Select,
  IconButton,
  Tooltip,
  AlertDialog,
  AlertDialogBody,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogCloseButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  Input,
  useToast,
  Button,
  Text,
} from "@chakra-ui/react";
import {
  AddIcon,
  CalendarIcon,
  CheckIcon,
  CloseIcon,
  CopyIcon,
  DeleteIcon,
  EditIcon,
} from "@chakra-ui/icons";
import { FaRegSave } from "react-icons/fa";

import { api } from "../../../../../utils/services/api";

import Content from "../components/ifccomposing/Content";

import config from "../../../utils/data/config.json";
import initialComposingData from "../../../utils/data/initialComposingData.json";
import idsTemplate from "../../../utils/data/idsTemplate.json";

import {
  composingList,
  composingRegister,
  composingDuplicate,
  composingEdit,
  composingRemove,
  composingSave,
} from "../../../utils/services/composings";

function IfcComposing({
  userInputs,
  ifcData,
  composingInputs,
  updateComposingInputs,
  composingsData,
  setComposingsData,
}) {
  const toast = useToast();
  const [formOpen, setFormOpen] = useState("");
  const [composingExistsWarning, setComposingExistsWarning] = useState(false);

  // Update database with optimist update
  function onRegisterComposing() {
    if (
      Object.keys(composingsData).some(
        (name) => name === composingInputs["name_input"]
      )
    ) {
      setComposingExistsWarning(true);
      setFormOpen("");
      updateComposingInputs("name_input", "");
    } else {
      composingRegister([userInputs, composingInputs]).then(() => {
        setComposingsData(() => {
          const mixComposings = composingsData;

          mixComposings[composingInputs["name_input"]] = {};
          return mixComposings;
        });
        updateComposingInputs("selected_name", composingInputs["name_input"]);
        updateComposingInputs("composing", {});
        setFormOpen("");
        updateComposingInputs("name_input", "");
      });
    }
  }

  function onDuplicateComposing() {
    if (
      Object.keys(composingsData).some(
        (name) => name === composingInputs["name_input"]
      )
    ) {
      setComposingExistsWarning(true);
      setFormOpen("");
      updateComposingInputs("name_input", "");
    } else {
      composingDuplicate([userInputs, composingInputs]).then(() => {
        setComposingsData(() => {
          const mixComposings = composingsData;

          mixComposings[composingInputs["name_input"]] =
            composingInputs["composing"];
          return mixComposings;
        });
        updateComposingInputs("selected_name", composingInputs["name_input"]);
        setFormOpen("");
        updateComposingInputs("name_input", "");
      });
    }
  }

  function onEditComposing() {
    if (
      Object.keys(composingsData).some(
        (name) => name === composingInputs["name_input"]
      )
    ) {
      setComposingExistsWarning(true);
      setFormOpen("");
      updateComposingInputs("name_input", "");
    } else {
      composingEdit([userInputs, composingInputs]).then(() => {
        setComposingsData(() => {
          const mixComposings = composingsData;

          mixComposings[composingInputs["name_input"]] =
            composingInputs["composing"];
          delete mixComposings[composingInputs["selected_name"]];
          return mixComposings;
        });
        updateComposingInputs("selected_name", composingInputs["name_input"]);
        setFormOpen("");
        updateComposingInputs("name_input", "");
      });
    }
  }

  function onRemoveComposing() {
    composingRemove([userInputs, composingInputs]).then(() => {
      setComposingsData(() => {
        const mixComposings = composingsData;

        delete mixComposings[composingInputs["selected_name"]];
        return mixComposings;
      });
      updateComposingInputs("selected_name", Object.keys(composingsData)[0]);
      updateComposingInputs(
        "composing",
        composingsData[Object.keys(composingsData)[0]]
      );
    });
  }

  function onSaveComposing() {
    const loadingToastId = toast({
      title: "Salvando informações da composição",
      description: "Por favor, aguarde...",
      status: "info",
      duration: null, // Mantém o toast até que seja fechado manualmente ou atualizado
      isClosable: false, // Não permitir que o usuário feche o toast
    });
    composingSave([userInputs, composingInputs]).then(() => {
      setComposingsData(() => {
        const mixComposings = composingsData;

        mixComposings[composingInputs["selected_name"]] =
          composingInputs["composing"];
        return mixComposings;
      });
      toast.update(loadingToastId, {
        title: "As informações da composição foram salvas com sucesso",
        description: "Avance para a próxima pagina!",
        status: "success",
        duration: 5000, // Duração de 5 segundos
        isClosable: true, // Permitir que o usuário feche o toast
      });
    });
    // Implementar Toast
  }

  function onChangeSelectedComposing(ev) {
    const composingName = ev.target.value;
    updateComposingInputs("selected_name", composingName);
    updateComposingInputs("composing", composingsData[composingName]);
  }

  function buildIdsFile(composing) {
    const composingResume = {};
    Object.entries(composing).map(([content, categories]) => {
      Object.entries(categories).map(([category, categoryConfigs]) => {
        const ifcType = categoryConfigs["ifcType"].toUpperCase();
        if (!(ifcType in composingResume)) {
          composingResume[ifcType] = {};
        }
        if (categoryConfigs["filterGroup"] !== "-") {
          if (categoryConfigs["filterParameter"] in composingResume[ifcType]) {
            //Adding a new property value by a category filter
            composingResume[ifcType][categoryConfigs["filterParameter"]][
              "value"
            ].push(categoryConfigs["filterValue"]);
          } else {
            //Creating new property by a category filter
            composingResume[ifcType][categoryConfigs["filterParameter"]] = {
              dataType: "IFCTEXT",
              propertySet: categoryConfigs["filterGroup"],
              baseName: categoryConfigs["filterParameter"],
              value: [categoryConfigs["filterValue"]],
            };
          }
        }
        Object.entries(categoryConfigs["instances"]).map(
          ([instance, instanceConfigs]) => {
            if (
              !(instanceConfigs["parameter"] in composingResume[ifcType]) &&
              instanceConfigs["parameter"] !== ""
            ) {
              //Creating a new property by a instance configs
              composingResume[ifcType][instanceConfigs["parameter"]] = {
                dataType: config["units"][instanceConfigs["unit"]],
                propertySet: instanceConfigs["group"],
                baseName: instanceConfigs["parameter"],
                value: "",
              };
            }
          }
        );
      });
    });

    const xml_configs = idsTemplate;
    Object.entries(composingResume).map(
      ([specificationName, properties], i) => {
        const xml_specification = {
          $: {
            name: "",
            ifcVersion: "",
            // "minOccurs": "0",
            // "maxOccurs": "unbounded"
          },
          applicability: {
            $: {
              minOccurs: "0",
              maxOccurs: "unbounded",
            },
            entity: {
              name: {
                simpleValue: "",
              },
            },
          },
          requirements: {
            property: [],
          },
        };
        xml_specification["$"]["ifcVersion"] = "IFC2X3";
        xml_specification["$"]["name"] = specificationName;
        xml_specification["applicability"]["entity"]["name"]["simpleValue"] =
          specificationName;
        Object.entries(properties).map(([propertyName, propertyConfigs]) => {
          const xml_property = {
            $: {
              dataType: "",
              // "minOccurs": "0",
              // "maxOccurs": "1"
            },
            propertySet: {
              simpleValue: "",
            },
            baseName: {
              simpleValue: "",
            },
            value: {
              "xs:restriction": {
                $: {
                  base: "",
                },
              },
            },
          };
          xml_property["$"]["dataType"] = propertyConfigs["dataType"];
          xml_property["propertySet"]["simpleValue"] =
            propertyConfigs["propertySet"];
          xml_property["baseName"]["simpleValue"] = propertyConfigs["baseName"];
          if (propertyConfigs["dataType"] === "IFCTEXT") {
            xml_property["value"]["xs:restriction"]["$"]["base"] = "xs:string";
            xml_property["value"]["xs:restriction"]["xs:enumeration"] = [];
            propertyConfigs["value"].map((value) => {
              xml_property["value"]["xs:restriction"]["xs:enumeration"].push({
                $: { value: value },
              });
            });
          } else {
            xml_property["value"]["xs:restriction"]["$"]["base"] = "xs:real";
            xml_property["value"]["xs:restriction"]["xs:minInclusive"] = {
              $: { value: "0." },
            };
          }
          xml_specification["requirements"]["property"].push(xml_property);
        });
        xml_configs["ids"]["specifications"]["specification"].push(
          xml_specification
        );
      }
    );

    const builder = new Builder();
    const xml = builder.buildObject(xml_configs);
    const file = new Blob([xml], {
      type: "appplication/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    const filename = "QuantitArs.ids.xml";
    saveAs(file, filename);
  }
  {
    /* Fixed because of database shutdown */
  }
  // useEffect(() => {
  //   api.post("/composing", userInputs).then((response) => {
  //     setComposingsData(() => {
  //       const mixComposings = { Template_ARS: initialComposingData };
  //       updateComposingInputs("selected_name", "Template_ARS");
  //       updateComposingInputs("composing", initialComposingData);

  //       response["data"].map((data) => {
  //         mixComposings[data["name"]] = data["composing"];
  //       });
  //       return mixComposings;
  //     });
  //   });
  // }, []);

  return (
    <div className={styles.section}>
      <Box padding="10px 0px" display="flex" justifyContent="space-between">
        <Box display="flex">
          <Select
            w="250px"
            size="md"
            borderColor="black"
            borderRadius="25px"
            value={composingInputs["selected_name"]}
            onChange={onChangeSelectedComposing}
          >
            {Object.keys(composingsData).map((composingName) => (
              <option key={composingName}>{composingName}</option>
            ))}
          </Select>
          <Popover
            isOpen={formOpen === "register"}
            onOpen={() => setFormOpen("register")}
            onClose={() => setFormOpen("")}
          >
            <PopoverTrigger>
              <IconButton
                icon={
                  <Tooltip
                    hasArrow
                    label="Adicionar"
                    bg="white"
                    color="black"
                    fontSize="md"
                  >
                    <AddIcon />
                  </Tooltip>
                }
                size="md"
                variant="outline"
                borderColor="black"
                borderRadius="25px"
                margin="0px 2px"
                isDisabled={composingInputs["selected_name"] === "Template_ARS"}
              />
            </PopoverTrigger>
            <PopoverContent flexDirection="row">
              <PopoverArrow backgroundColor="black" />
              <Input
                type="text"
                placeholder="Digite o nome da composição"
                onChange={(ev) =>
                  updateComposingInputs("name_input", ev.target.value)
                }
                value={composingInputs["name_input"]}
              />
              <IconButton icon={<CheckIcon />} onClick={onRegisterComposing} />
              <IconButton
                icon={<CloseIcon />}
                onClick={() => {
                  setFormOpen("");
                  updateComposingInputs("name_input", "");
                }}
              />
            </PopoverContent>
          </Popover>
          <Popover
            isOpen={formOpen === "duplicate"}
            onOpen={() => setFormOpen("duplicate")}
            onClose={() => setFormOpen("")}
          >
            <PopoverTrigger>
              <IconButton
                icon={
                  <Tooltip
                    hasArrow
                    label="Duplicar"
                    bg="white"
                    color="black"
                    fontSize="md"
                  >
                    <CopyIcon />
                  </Tooltip>
                }
                size="md"
                variant="outline"
                borderColor="black"
                borderRadius="25px"
                margin="0px 2px"
                isDisabled={composingInputs["selected_name"] === "Template_ARS"}
              />
            </PopoverTrigger>
            <PopoverContent flexDirection="row">
              <PopoverArrow backgroundColor="black" />
              <Input
                type="text"
                placeholder="Digite o nome da composição"
                onChange={(ev) =>
                  updateComposingInputs("name_input", ev.target.value)
                }
                value={composingInputs["name_input"]}
              />
              <IconButton icon={<CheckIcon />} onClick={onDuplicateComposing} />
              <IconButton
                icon={<CloseIcon />}
                onClick={() => {
                  setFormOpen("");
                  updateComposingInputs("name_input", "");
                }}
              />
            </PopoverContent>
          </Popover>
          <Popover
            isOpen={formOpen === "edit"}
            onOpen={() => setFormOpen("edit")}
            onClose={() => setFormOpen("")}
          >
            <PopoverTrigger>
              <IconButton
                icon={
                  <Tooltip
                    hasArrow
                    label="Renomear"
                    bg="white"
                    color="black"
                    fontSize="md"
                  >
                    <EditIcon />
                  </Tooltip>
                }
                size="md"
                variant="outline"
                borderColor="black"
                borderRadius="25px"
                margin="0px 2px"
                isDisabled={composingInputs["selected_name"] === "Template_ARS"}
              />
            </PopoverTrigger>
            <PopoverContent flexDirection="row">
              <PopoverArrow backgroundColor="black" />
              <Input
                type="text"
                placeholder="Digite o nome da composição"
                onChange={(ev) =>
                  updateComposingInputs("name_input", ev.target.value)
                }
                value={composingInputs["name_input"]}
              />
              <IconButton icon={<CheckIcon />} onClick={onEditComposing} />
              <IconButton
                icon={<CloseIcon />}
                onClick={() => {
                  setFormOpen("");
                  updateComposingInputs("name_input", "");
                }}
              />
            </PopoverContent>
          </Popover>
          <IconButton
            icon={
              <Tooltip
                hasArrow
                label="Remover"
                bg="white"
                color="black"
                fontSize="md"
              >
                <DeleteIcon />
              </Tooltip>
            }
            size="md"
            variant="outline"
            borderColor="black"
            borderRadius="25px"
            margin="0px 2px"
            isDisabled={composingInputs["selected_name"] === "Template_ARS"}
            onClick={onRemoveComposing}
          />
        </Box>
        <IconButton
          icon={<FaRegSave size={20} />}
          size="md"
          variant="outline"
          borderColor="black"
          borderRadius="25px"
          margin="0px 2px"
          isDisabled={composingInputs["selected_name"] === "Template_ARS"}
          onClick={onSaveComposing}
        />
      </Box>
      <Content
        ifcData={ifcData}
        composing={composingInputs["composing"]}
        updateComposingInputs={updateComposingInputs}
      />
      <Box padding="10px 0px" display="flex" justifyContent="space-between">
        <Button
          onClick={() => buildIdsFile(composingInputs["composing"])}
          colorScheme="blue"
          variant="outline"
          flexDirection="row"
        >
          <CalendarIcon marginRight="10px" />
          <Text>Exportar .ids</Text>
        </Button>
      </Box>
      {composingExistsWarning && (
        <AlertDialog isOpen={composingExistsWarning}>
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader>A composição já existe</AlertDialogHeader>
              <AlertDialogCloseButton
                onClick={() => setComposingExistsWarning(false)}
              />
              <AlertDialogBody>
                Uma composição com esse nome já existe. Escolha um nome
                diferente.
              </AlertDialogBody>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      )}
    </div>
  );
}

export default IfcComposing;
