import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";

import Home from "./pages/Home";

function Main({ userInputs, updateUserInputs }) {
  return <Home userInputs={userInputs} updateUserInputs={updateUserInputs} />;
}

export default Main;
