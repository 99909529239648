import React, { useEffect, useState } from "react";

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Box,
  IconButton,
  Tooltip,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  Input,
} from "@chakra-ui/react";
import {
  AddIcon,
  CheckIcon,
  CloseIcon,
  DeleteIcon,
  EditIcon,
} from "@chakra-ui/icons";

import Category from "./Category";
import AddContent from "./AddContent";
import RenameContent from "./RenameContent";

function Content({ ifcData, composing, updateComposingInputs }) {
  // Add / Rename / Remove Contents functions
  function onAddContentAction(content) {
    const newcomposing = { ...composing };
    newcomposing[content] = {};
    updateComposingInputs("composing", newcomposing);
  }

  function onRenameContentAction(content, new_content) {
    const newcomposing = { ...composing };
    newcomposing[new_content] = newcomposing[content];
    delete newcomposing[content];
    updateComposingInputs("composing", newcomposing);
  }

  function onRemoveContentAction(content) {
    const newcomposing = { ...composing };
    delete newcomposing[content];
    updateComposingInputs("composing", newcomposing);
  }

  return (
    <>
      <Accordion defaultIndex={[0]} allowMultiple>
        {Object.entries(composing).map(([content, categories], i) => (
          <AccordionItem key={i} borderStyle="none">
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              margin="2px 0px"
            >
              <AccordionButton
                flex="1"
                bg="blue.600"
                borderRadius="100px"
                justifyContent="space-between"
              >
                <Box textColor="white" textAlign="left">
                  {i + 1 + ". " + content}
                </Box>
              </AccordionButton>
              <RenameContent
                content={content}
                onRenameContentAction={onRenameContentAction}
              />
              <IconButton
                icon={
                  <Tooltip
                    hasArrow
                    label="Remover Disciplina"
                    bg="white"
                    color="black"
                    fontSize="md"
                  >
                    <DeleteIcon />
                  </Tooltip>
                }
                size="md"
                onClick={() => onRemoveContentAction(content)}
              />
            </Box>
            <AccordionPanel pb={4}>
              <Category
                ifcData={ifcData}
                composing={composing}
                updateComposingInputs={updateComposingInputs}
                content={content}
                i={i}
                categories={categories}
              />
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
      <AddContent onAddContentAction={onAddContentAction} />
    </>
  );
}

export default Content;
