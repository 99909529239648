import styles from "./Login.module.css";

import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";

import { Text } from "@chakra-ui/react";

function Login({ responseGoogle }) {
  return (
    <div className={styles.section}>
      <Text>Para acessar o programa, logue sua conta:</Text>
      <GoogleOAuthProvider clientId="197839581355-u4s6jhdp7p4hmfgcc14geh4kvoc7o2pl.apps.googleusercontent.com">
        <GoogleLogin
          onSuccess={responseGoogle}
          onError={(error) => console.log("O login falhou:", error)}
        />
      </GoogleOAuthProvider>
    </div>
  );
}

export default Login;
