import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Box, Flex, Text, List, ListItem, IconButton } from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { BsHouse } from "react-icons/bs";

import styles from "./Home.module.css";

import IfcModel from "./home/containers/IfcModel";
import IfcComposing from "./home/containers/IfcComposing";
import IfcFilters from "./home/containers/IfcFilters";
import IfcTable from "./home/containers/IfcTable";

import initialComposingData from "../utils/data/initialComposingData.json";

function Home({ userInputs }) {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState("Modelo");
  const [ifcFileName, setIfcFileName] = useState("");
  const [ifcData, setIfcData] = useState("");
  const [composingInputs, setComposingInputs] = useState({
    selected_name: "Template_ARS",
    name_input: "",
    composing: initialComposingData,
  });
  const [composingsData, setComposingsData] = useState({
    Template_ARS: initialComposingData,
  });

  function updateComposingInputs(name, value) {
    setComposingInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
  }

  const handleNext = () => {
    const options = Object.keys(componentMap);
    const currentIndex = options.indexOf(selectedOption);
    const nextIndex = (currentIndex + 1) % options.length;
    setSelectedOption(options[nextIndex]);
  };

  const handlePrevious = () => {
    const options = Object.keys(componentMap);
    const currentIndex = options.indexOf(selectedOption);
    const previousIndex = (currentIndex - 1 + options.length) % options.length;
    setSelectedOption(options[previousIndex]);
  };

  const componentMap = {
    Modelo: (
      <IfcModel
        ifcFileName={ifcFileName}
        setIfcFileName={setIfcFileName}
        setIfcData={setIfcData}
        handleNext={handleNext}
      />
    ),
    Composições: (
      <IfcComposing
        userInputs={userInputs}
        ifcData={ifcData}
        composingInputs={composingInputs}
        updateComposingInputs={updateComposingInputs}
        composingsData={composingsData}
        setComposingsData={setComposingsData}
      />
    ),
    // Setorização: <IfcFilters />,
    Tabela: (
      <IfcTable ifcData={ifcData} composing={composingInputs["composing"]} />
    ),
  };

  return (
    <div className={styles.bodyQuantitars}>
      <div className={styles.header}>
        <IconButton
          icon={<BsHouse size={20} color="var(--text-color)" />}
          borderRadius="none"
          background="none"
          onClick={() => navigate("/")}
        />
        <Flex flex={1} justify="center" align="center" ml={-5}>
          {selectedOption !== "Modelo" ? (
            <ChevronLeftIcon
              w={8}
              h={8}
              color="var(--text-color)"
              cursor="pointer"
              onClick={handlePrevious}
              _hover={{ color: "var(--primary-color)" }}
            />
          ) : (
            <Box></Box>
          )}
          <List display="flex">
            {Object.keys(componentMap).map((option, index) => (
              <React.Fragment key={index}>
                {index > 0 && (
                  <Flex align="center">
                    <ChevronRightIcon color="var(--text-color)" />
                    <Box ml={1}>
                      <ListItem borderRadius="md" p={2} mx={1}>
                        <Text
                          color={
                            selectedOption === option
                              ? "var(--primary-color)"
                              : "var(--text-color)"
                          }
                        >
                          {index + 1 + ". " + option}
                        </Text>
                      </ListItem>
                    </Box>
                  </Flex>
                )}
                {index === 0 && (
                  <ListItem borderRadius="md" p={2} mx={1}>
                    <Text
                      color={
                        selectedOption === option
                          ? "var(--primary-color)"
                          : "var(--text-color)"
                      }
                    >
                      {index + 1 + ". " + option}
                    </Text>
                  </ListItem>
                )}
              </React.Fragment>
            ))}
          </List>
          {selectedOption !== "Tabela" && ifcData !== "" ? (
            <ChevronRightIcon
              w={8}
              h={8}
              color="var(--text-color)"
              cursor="pointer"
              onClick={handleNext}
              _hover={{ color: "var(--primary-color)" }}
            />
          ) : (
            <Box></Box>
          )}
        </Flex>
      </div>
      <div className={styles.main}>{componentMap[selectedOption]}</div>
    </div>
  );
}

export default Home;
