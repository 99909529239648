import React, { useState } from "react";

import {
  IconButton,
  Tooltip,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  Input,
} from "@chakra-ui/react";
import { EditIcon, CheckIcon, CloseIcon } from "@chakra-ui/icons";

function RenameCategory({ content, category, onRenameCategoryAction }) {
  const [formOpen, setFormOpen] = useState("");
  const [categoryInput, setCategoryInput] = useState("");

  return (
    <Popover
      placement="bottom"
      isOpen={formOpen === "rename_category_" + category}
      onOpen={() => setFormOpen("rename_category_" + category)}
      onClose={() => setFormOpen("")}
    >
      <PopoverTrigger>
        <IconButton
          icon={
            <Tooltip
              hasArrow
              label="Renomear Categoria"
              bg="white"
              color="black"
              fontSize="md"
            >
              <EditIcon />
            </Tooltip>
          }
          size="md"
        />
      </PopoverTrigger>
      <PopoverContent flexDirection={"row"}>
        <PopoverArrow backgroundColor={"black"} />
        <Input
          type="text"
          placeholder="Digite o nome da categoria"
          onChange={(ev) => setCategoryInput(ev.target.value)}
          value={categoryInput}
        />
        <IconButton
          icon={<CheckIcon />}
          onClick={() => {
            onRenameCategoryAction(content, category, categoryInput);
            setFormOpen("");
            setCategoryInput("");
          }}
        />
        <IconButton
          icon={<CloseIcon />}
          onClick={() => {
            setFormOpen("");
            setCategoryInput("");
          }}
        />
      </PopoverContent>
    </Popover>
  );
}

export default RenameCategory;
