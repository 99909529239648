import styles from "./Updates.module.css";

function Updates({ updates, popupSelected }) {
  return (
    <div className={styles.section}>
      <h1>Próximas atualizações {popupSelected}</h1>
      {Object.entries(updates[popupSelected]).map(([step, infos]) => (
        <>
          <h2>{step}</h2>
          <div className={styles.separator}></div>
          <img
            className={styles.tutorialImage}
            src={require("../../../utils/img/" + infos["image"])}
            alt={step}
          />
          {infos.description.map((desc, index) => (
            <p>{desc}</p>
          ))}
        </>
      ))}
    </div>
    // <Flex className={styles.sectionTutorials}>
    //   {/* Conteúdo principal na direita */}
    //   <Box flex="1" p={4} overflowY="auto">
    //     <h3>
    //       <strong>
    //         ACOMPANHE DE MANEIRA TRANSPARENTE O QUE ESTÁ SENDO DESENVOLVIDO PELA
    //         NOSSA EQUIPE E O QUE SERÁ DISPONIBILIZADO NAS PRÓXIMAS ATUALIZAÇÕES
    //         DE CADA PRODUTO
    //       </strong>
    //       <br></br>
    //       <strong>
    //         PARTICIPE DO DESENVOLVIMENTO DAS FERRAMENTAS DANDO SUGESTÕES NA ABA
    //         DE SUGESTÕES/SUPORTE
    //       </strong>
    //     </h3>
    //     {/* Adicionando scroll vertical */}
    //     {selectedAtualizacoes &&
    //       Object.entries(updates[selectedAtualizacoes]).map(([step, infos]) => (
    //         <Box key={step} mb={8}>
    //           <h3>
    //             <strong>{step}</strong>
    //           </h3>
    //           <Image
    //             src={require("../../../utils/img/" + infos["image"])}
    //             alt={step}
    //           />
    //           <List mt={4}>
    //             {infos.description.map((desc, index) => (
    //               <ListItem key={index}>{desc}</ListItem>
    //             ))}
    //           </List>
    //         </Box>
    //       ))}
    //   </Box>
    // </Flex>
  );
}

export default Updates;
