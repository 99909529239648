import React, { useEffect, useState } from "react";

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Box,
  Select,
  IconButton,
  Tooltip,
  Text,
  Input,
  Modal,
  ModalOverlay,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalBody,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import { DeleteIcon, SettingsIcon } from "@chakra-ui/icons";

import Instance from "./Instance";
import AddCategory from "./AddCategory";
import ConfigCategory from "./ConfigCategory";
import RenameCategory from "./RenameCategory";

function Category({
  ifcData,
  composing,
  updateComposingInputs,
  content,
  i,
  categories,
}) {
  const [categorySettings, setCategorySettings] = useState({
    content: "",
    category: "",
  });

  function updateCategorySettings(name, value) {
    setCategorySettings((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  }

  // Add / Rename / Remove Categories functions
  function onAddCategoryAction(content, category) {
    const newcomposing = { ...composing };
    newcomposing[content][category] = {
      ifcType: Object.keys(ifcData)[0],
      filterGroup: "-",
      filterParameter: "",
      filterValue: "",
      instances: {},
    };
    updateComposingInputs("composing", newcomposing);
  }

  function onRenameCategoryAction(content, category, new_category) {
    const newcomposing = { ...composing };
    newcomposing[content][new_category] = newcomposing[content][category];
    delete newcomposing[content][category];
    updateComposingInputs("composing", newcomposing);
  }

  function onRemoveCategoryAction(content, category) {
    const newcomposing = { ...composing };
    delete newcomposing[content][category];
    updateComposingInputs("composing", newcomposing);
  }

  // Open Category configs form function
  function openCategorySettings(content, category) {
    updateCategorySettings("content", content);
    updateCategorySettings("category", category);
  }

  // Verify if Category configs exists in ifc functions
  function categoryIfcTypeExist(ifcType) {
    try {
      const exist = ifcType in ifcData;
      return exist;
    } catch {
      return false;
    }
  }

  function categoryFilterGroupExist(ifcType, group) {
    try {
      const exist = group in ifcData[ifcType][0];
      return exist;
    } catch {
      return false;
    }
  }

  function categoryFilterParameterExist(ifcType, group, parameter) {
    try {
      const exist = parameter in ifcData[ifcType][0][group];
      return exist;
    } catch {
      return false;
    }
  }

  return (
    <>
      <Accordion allowMultiple>
        {Object.entries(categories).map(([category, categorySettings], j) => (
          <AccordionItem key={j} borderStyle="none" padding="1px">
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
              <AccordionButton
                flex="1"
                bg="blue.300"
                borderRadius="100px"
                justifyContent="space-between"
              >
                <Box textColor="white" textAlign="left">
                  {i + 1 + "." + (j + 1) + ". " + category}
                </Box>
                <Box display="flex" flexDirection="row">
                  <Text color="white">
                    {"Tipo: " + categorySettings["ifcType"]}
                  </Text>
                  {categorySettings["filterGroup"] !== "-" ? (
                    <>
                      <Text color="white">
                        {"=>Grupo: " + categorySettings["filterGroup"]}
                      </Text>
                      <Text color="white">
                        {"=>" +
                          categorySettings["filterParameter"] +
                          " = " +
                          categorySettings["filterValue"]}
                      </Text>
                    </>
                  ) : (
                    <></>
                  )}
                </Box>
              </AccordionButton>
              <IconButton
                icon={
                  <Tooltip
                    hasArrow
                    label="Configurar Categoria"
                    bg="white"
                    color="black"
                    fontSize="md"
                  >
                    <SettingsIcon />
                  </Tooltip>
                }
                size="md"
                onClick={() => openCategorySettings(content, category)}
              />
              <RenameCategory
                content={content}
                category={category}
                onRenameCategoryAction={onRenameCategoryAction}
              />
              <IconButton
                icon={
                  <Tooltip
                    placement="left"
                    hasArrow
                    label="Remover Categoria"
                    bg="white"
                    color="black"
                    fontSize="md"
                  >
                    <DeleteIcon />
                  </Tooltip>
                }
                size="md"
                onClick={() => {
                  onRemoveCategoryAction(content, category);
                }}
              />
            </Box>
            <AccordionPanel pb={4}>
              <Instance
                ifcData={ifcData}
                composing={composing}
                updateComposingInputs={updateComposingInputs}
                categoryIfcTypeExist={categoryIfcTypeExist}
                content={content}
                i={i}
                category={category}
                j={j}
                instances={categorySettings}
              />
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
      <AddCategory
        content={content}
        onAddCategoryAction={onAddCategoryAction}
      />
      <ConfigCategory
        ifcData={ifcData}
        composing={composing}
        updateComposingInputs={updateComposingInputs}
        categorySettings={categorySettings}
        updateCategorySettings={updateCategorySettings}
        categoryIfcTypeExist={categoryIfcTypeExist}
        categoryFilterGroupExist={categoryFilterGroupExist}
        categoryFilterParameterExist={categoryFilterParameterExist}
      />
    </>
  );
}

export default Category;
