import React, { useState } from "react";

import {
  IconButton,
  Tooltip,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  Input,
} from "@chakra-ui/react";
import { AddIcon, CheckIcon, CloseIcon } from "@chakra-ui/icons";

function AddCategory({ content, onAddCategoryAction }) {
  const [formOpen, setFormOpen] = useState("");
  const [categoryInput, setCategoryInput] = useState("");

  return (
    <Popover
      placement="right"
      isOpen={formOpen === "add_category_" + content}
      onOpen={() => setFormOpen("add_category_" + content)}
      onClose={() => setFormOpen("")}
    >
      <PopoverTrigger>
        <IconButton
          icon={
            <Tooltip
              hasArrow
              label="Adicionar Categoria"
              bg="white"
              color="black"
              fontSize="md"
            >
              <AddIcon color="white" />
            </Tooltip>
          }
          size="md"
          bg="blue.300"
          borderRadius="100px"
        />
      </PopoverTrigger>
      <PopoverContent flexDirection={"row"}>
        <PopoverArrow backgroundColor={"black"} />
        <Input
          type="text"
          placeholder="Digite o nome da categoria"
          onChange={(ev) => setCategoryInput(ev.target.value)}
          value={categoryInput}
        />
        <IconButton
          icon={<CheckIcon />}
          onClick={() => {
            onAddCategoryAction(content, categoryInput);
            setFormOpen("");
            setCategoryInput("");
          }}
        />
        <IconButton
          icon={<CloseIcon />}
          onClick={() => {
            setFormOpen("");
            setCategoryInput("");
          }}
        />
      </PopoverContent>
    </Popover>
  );
}

export default AddCategory;
