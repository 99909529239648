import React, { useState } from "react";

import {
  IconButton,
  Tooltip,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  Input,
} from "@chakra-ui/react";
import { EditIcon, CheckIcon, CloseIcon } from "@chakra-ui/icons";

function RenameContent({ content, onRenameContentAction }) {
  const [formOpen, setFormOpen] = useState("");
  const [contentInput, setContentInput] = useState("");

  return (
    <Popover
      placement="bottom"
      isOpen={formOpen === "rename_content_" + content}
      onOpen={() => setFormOpen("rename_content_" + content)}
      onClose={() => setFormOpen("")}
    >
      <PopoverTrigger>
        <IconButton
          icon={
            <Tooltip
              hasArrow
              label="Renomear Disciplina"
              bg="white"
              color="black"
              fontSize="md"
            >
              <EditIcon />
            </Tooltip>
          }
          size="md"
        />
      </PopoverTrigger>
      <PopoverContent flexDirection={"row"}>
        <PopoverArrow backgroundColor={"black"} />
        <Input
          type="text"
          placeholder="Digite o nome da disciplina"
          onChange={(ev) => setContentInput(ev.target.value)}
          value={contentInput}
        />
        <IconButton
          icon={<CheckIcon />}
          onClick={() => {
            onRenameContentAction(content, contentInput);
            setFormOpen("");
            setContentInput("");
          }}
        />
        <IconButton
          icon={<CloseIcon />}
          onClick={() => {
            setFormOpen("");
            setContentInput("");
          }}
        />
      </PopoverContent>
    </Popover>
  );
}

export default RenameContent;
