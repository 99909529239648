import { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Editable,
  EditableInput,
  EditablePreview,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Select,
  Tab,
  TabList,
  Table,
  TableContainer,
  Tabs,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { IconButton, Input, Tooltip } from "@chakra-ui/react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
} from "@chakra-ui/react";
import {
  CheckIcon,
  CloseIcon,
  CopyIcon,
  DeleteIcon,
  EditIcon,
} from "@chakra-ui/icons";
import { FaRegSave } from "react-icons/fa";
import { CgLogOut } from "react-icons/cg";

import styles from "./ParametersManager.module.css";

import parametersMethods from "../utils/data/parametersMethods.json";

import {
  methodDuplicate,
  methodEdit,
  methodRemove,
  methodSave,
} from "../utils/services/methods";

function ParametersManager({
  userInputs,
  methodInputs,
  setMethodInputs,
  updateMethodInputs,
  methodsData,
  setUpdateMethods,
}) {
  const navigate = useNavigate();
  const [formOpen, setFormOpen] = useState("");
  const [projectExistsWarning, setProjectExistsWarning] = useState(false);

  function onMethodSelectedChange(ev) {
    const value = ev.target.value;
    updateMethodInputs("selected_name", value);
    if (value === "Aoki-Velloso" || value === "Decourt-Quaresma") {
      updateMethodInputs("parameters", parametersMethods["estacas"][value]);
    } else if (value === "Bulbo de Tensões") {
      updateMethodInputs("parameters", parametersMethods["sapatas"][value]);
    } else {
      updateMethodInputs(
        "parameters",
        methodsData[methodInputs["foundation_class"]][value]
      );
    }
  }

  function onMethodInputChange(ev) {
    const value = ev.target.value;
    updateMethodInputs("name_input", value);
  }

  function onMethodFoundationClassChange(ev) {
    updateMethodInputs("foundation_class", Object.keys(parametersMethods)[ev]);
    if (Object.keys(parametersMethods)[ev] === "estacas") {
      updateMethodInputs("selected_name", "Aoki-Velloso");
      updateMethodInputs(
        "parameters",
        parametersMethods["estacas"]["Aoki-Velloso"]
      );
    } else if (Object.keys(parametersMethods)[ev] === "sapatas") {
      updateMethodInputs("selected_name", "Bulbo de Tensões");
      updateMethodInputs(
        "parameters",
        parametersMethods["sapatas"]["Bulbo de Tensões"]
      );
    }
  }

  function onParameterInputChange(key, row, col, value) {
    setMethodInputs((prevInputs) => {
      const newState = { ...prevInputs };
      newState["parameters"][key][row][col] = parseFloat(value);
      return newState;
    });
  }

  function onMethodActions(action) {
    const options = {
      duplicate: () => {
        if (
          Object.keys({ ...methodsData.estacas, ...methodsData.sapatas }).some(
            (name) => name === methodInputs.name_input
          )
        ) {
          setProjectExistsWarning(true);
        } else {
          methodDuplicate([userInputs, methodInputs]).then(() => {
            setUpdateMethods((prev) => prev + 1);
          });
          updateMethodInputs("selected_name", methodInputs["name_input"]);
          setFormOpen("");
          updateMethodInputs("name_input", "");
        }
      },
      edit: () => {
        if (
          Object.keys({ ...methodsData.estacas, ...methodsData.sapatas }).some(
            (name) => name === methodInputs.name_input
          )
        ) {
          setProjectExistsWarning(true);
        } else {
          methodEdit([userInputs, methodInputs]).then(() => {
            setUpdateMethods((prev) => prev + 1);
          });
          updateMethodInputs("selected_name", methodInputs["name_input"]);
          setFormOpen("");
          updateMethodInputs("name_input", "");
        }
      },
      remove: () => {
        methodRemove([userInputs, methodInputs]).then(() => {
          setUpdateMethods((prev) => prev + 1);
        });
        updateMethodInputs(
          "selected_name",
          Object.keys(parametersMethods[methodInputs["foundation_class"]])[0]
        );
        updateMethodInputs(
          "parameters",
          parametersMethods[methodInputs["foundation_class"]][
            Object.keys(parametersMethods[methodInputs["foundation_class"]])[0]
          ]
        );
      },
    };
    for (const [key, value] of Object.entries(options)) {
      if (action === key) {
        value();
      }
    }
  }

  function onMethodSave() {
    methodSave([userInputs, methodInputs]);
  }

  function onCloseParameters() {
    updateMethodInputs("foundation_class", "estacas");
    navigate("/fundars");
  }

  function onReturn() {
    navigate("/fundars/home");
  }

  return (
    <>
      <div className={styles.page}>
        <div className={styles.menu}>
          <Tabs
            onChange={onMethodFoundationClassChange}
            variant="soft-rounded"
            colorScheme="blue"
            value={methodInputs["foundation_class"]}
          >
            <TabList>
              <Tab checked={methodInputs["foundation_class"] === "estacas"}>
                Métodos de Cálculo Estacas
              </Tab>
              <Tab checked={methodInputs["foundation_class"] === "sapatas"}>
                Métodos de Cálculo Sapatas
              </Tab>
            </TabList>
          </Tabs>
        </div>
        <div className={styles.menu}>
          <div className={styles.leftMenu}>
            <Select
              onChange={onMethodSelectedChange}
              variant="outline"
              w="200px"
              value={methodInputs["selected_name"]}
            >
              {Object.keys(methodsData[methodInputs["foundation_class"]]).map(
                (method) => (
                  <option key={method}>{method}</option>
                )
              )}
            </Select>
            <Popover
              isOpen={formOpen === "duplicate"}
              onOpen={() => setFormOpen("duplicate")}
              onClose={() => setFormOpen("")}
            >
              <PopoverTrigger>
                <IconButton
                  icon={
                    <Tooltip
                      hasArrow
                      label="Duplicar"
                      bg="gray"
                      color="black"
                      fontSize="md"
                    >
                      <CopyIcon />
                    </Tooltip>
                  }
                  borderWidth="sm"
                  borderRadius="none"
                  borderColor="border"
                  variant="solid"
                  colorScheme="blue"
                  isDisabled={
                    methodInputs["selected_name"] !== "Aoki-Velloso" &&
                    methodInputs["selected_name"] !== "Decourt-Quaresma" &&
                    methodInputs["selected_name"] !== "Bulbo de Tensões"
                  }
                />
              </PopoverTrigger>
              <PopoverContent flexDirection={"row"}>
                <PopoverArrow backgroundColor={"black"} />
                <Input
                  type="text"
                  placeholder="Digite o nome do método"
                  onChange={onMethodInputChange}
                  value={methodInputs["name_input"]}
                />
                <IconButton
                  icon={<CheckIcon />}
                  onClick={() => onMethodActions("duplicate")}
                />
                <IconButton
                  icon={<CloseIcon />}
                  onClick={() => {
                    setFormOpen("");
                    setMethodInputs((prevInputs) => ({
                      ...prevInputs,
                      ["name_input"]: "",
                    }));
                  }}
                />
              </PopoverContent>
            </Popover>
            <Popover
              isOpen={formOpen === "edit"}
              onOpen={() => setFormOpen("edit")}
              onClose={() => setFormOpen("")}
            >
              <PopoverTrigger>
                <IconButton
                  icon={
                    <Tooltip
                      hasArrow
                      label="Renomear"
                      bg="gray"
                      color="black"
                      fontSize="md"
                    >
                      <EditIcon />
                    </Tooltip>
                  }
                  borderWidth="sm"
                  borderRadius="none"
                  borderColor="border"
                  variant="solid"
                  colorScheme="blue"
                  isDisabled={
                    methodInputs["selected_name"] === "Aoki-Velloso" ||
                    methodInputs["selected_name"] === "Decourt-Quaresma" ||
                    methodInputs["selected_name"] === "Bulbo de Tensões"
                  }
                />
              </PopoverTrigger>
              <PopoverContent flexDirection={"row"}>
                <PopoverArrow backgroundColor={"black"} />
                <Input
                  type="text"
                  placeholder="Digite o nome do método"
                  onChange={onMethodInputChange}
                  value={methodInputs["name_input"]}
                />
                <IconButton
                  icon={<CheckIcon />}
                  onClick={() => onMethodActions("edit")}
                />
                <IconButton
                  icon={<CloseIcon />}
                  onClick={() => {
                    setFormOpen("");
                    setMethodInputs((prevInputs) => ({
                      ...prevInputs,
                      ["name_input"]: "",
                    }));
                  }}
                />
              </PopoverContent>
            </Popover>
            <IconButton
              icon={
                <Tooltip
                  hasArrow
                  label="Remover"
                  bg="gray"
                  color="black"
                  fontSize="md"
                >
                  <DeleteIcon />
                </Tooltip>
              }
              borderWidth="sm"
              borderRadius="none"
              borderColor="border"
              variant="solid"
              colorScheme="blue"
              onClick={() => {
                onMethodActions("remove");
              }}
              isDisabled={
                methodInputs["selected_name"] === "Aoki-Velloso" ||
                methodInputs["selected_name"] === "Decourt-Quaresma" ||
                methodInputs["selected_name"] === "Bulbo de Tensões"
              }
            />
          </div>
          <div className={styles.rightMenu}>
            <Tooltip
              hasArrow
              label="Salvar"
              bg="gray"
              color="black"
              fontSize="md"
            >
              <IconButton
                icon={<FaRegSave size={20} />}
                borderWidth="sm"
                borderRadius="none"
                borderColor="border"
                variant="solid"
                colorScheme="blue"
                onClick={() => {
                  onMethodSave();
                }}
              />
            </Tooltip>
            <Tooltip
              hasArrow
              label="Voltar"
              bg="gray"
              color="black"
              fontSize="md"
            >
              <IconButton
                icon={<CgLogOut size={20} />}
                borderWidth="sm"
                borderRadius="none"
                borderColor="border"
                variant="solid"
                colorScheme="blue"
                onClick={() => {
                  onCloseParameters();
                }}
              />
            </Tooltip>
          </div>
        </div>
        <div className={styles.section}>
          <Accordion defaultIndex={[0]} allowMultiple w="100%">
            {Object.entries(methodInputs["parameters"]).map(([key, value]) => {
              return (
                <AccordionItem>
                  {key === "TIPO DO MÉTODO" ? (
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        <strong>
                          {key}: {value}
                        </strong>
                      </Box>
                    </AccordionButton>
                  ) : (
                    <>
                      <AccordionButton>
                        <Box as="span" flex="1" textAlign="left">
                          <strong>{key}</strong>
                        </Box>
                      </AccordionButton>
                      <AccordionPanel pb={4}>
                        <TableContainer>
                          <Table variant="striped" colorScheme="gray" size="sm">
                            <Thead>
                              <Tr>
                                {methodInputs["selected_name"] ===
                                  "Aoki-Velloso" ||
                                methodInputs["selected_name"] ===
                                  "Decourt-Quaresma" ||
                                methodInputs["selected_name"] ==
                                  "Bulbo de Tensões"
                                  ? Object.keys(value[0]).map((header) => (
                                      <Th key={header}>{header}</Th>
                                    ))
                                  : Object.keys(
                                      parametersMethods[
                                        methodInputs["foundation_class"]
                                      ][
                                        methodInputs["parameters"][
                                          "TIPO DO MÉTODO"
                                        ]
                                      ][key][0]
                                    ).map((header) => (
                                      <Th key={header}>{header}</Th>
                                    ))}
                              </Tr>
                            </Thead>
                            <Tbody>
                              {value.map((element, i) => (
                                <Tr key={i}>
                                  {methodInputs["selected_name"] ===
                                    "Aoki-Velloso" ||
                                  methodInputs["selected_name"] ===
                                    "Decourt-Quaresma" ||
                                  methodInputs["selected_name"] ==
                                    "Bulbo de Tensões"
                                    ? Object.values(element).map(
                                        (content, col_index) => (
                                          <Td key={col_index}>
                                            <Box fontSize="md">{content}</Box>
                                          </Td>
                                        )
                                      )
                                    : Object.keys(
                                        parametersMethods[
                                          methodInputs["foundation_class"]
                                        ][
                                          methodInputs["parameters"][
                                            "TIPO DO MÉTODO"
                                          ]
                                        ][key][0]
                                      ).map((header, col_index) => (
                                        <Td key={col_index}>
                                          {methodInputs["foundation_class"] ===
                                            "estacas" &&
                                          methodInputs["selected_name"] !==
                                            "Aoki-Velloso" &&
                                          methodInputs["selected_name"] !==
                                            "Decourt-Quaresma" &&
                                          col_index !== 0 ? (
                                            <Editable
                                              defaultValue={
                                                element[header] || "0"
                                              }
                                              fontSize="md"
                                            >
                                              <EditablePreview />
                                              <EditableInput
                                                textAlign="start"
                                                onKeyPress={(event) => {
                                                  if (
                                                    !/[0-9.]/.test(event.key)
                                                  ) {
                                                    event.preventDefault();
                                                  }
                                                }}
                                                onChange={(event) => {
                                                  onParameterInputChange(
                                                    key,
                                                    i,
                                                    header,
                                                    event.target.value
                                                  );
                                                }}
                                              />
                                            </Editable>
                                          ) : methodInputs[
                                              "foundation_class"
                                            ] === "sapatas" &&
                                            methodInputs["selected_name"] !==
                                              "Bulbo de Tensões" &&
                                            col_index > 1 ? (
                                            <Editable
                                              defaultValue={
                                                element[header] || "0"
                                              }
                                              fontSize="md"
                                            >
                                              <EditablePreview />
                                              <EditableInput
                                                textAlign="start"
                                                onKeyPress={(event) => {
                                                  if (
                                                    !/[0-9.]/.test(event.key)
                                                  ) {
                                                    event.preventDefault();
                                                  }
                                                }}
                                                onChange={(event) => {
                                                  onParameterInputChange(
                                                    key,
                                                    i,
                                                    header,
                                                    event.target.value
                                                  );
                                                }}
                                              />
                                            </Editable>
                                          ) : (
                                            <Box fontSize="md">
                                              {element[header]}
                                            </Box>
                                          )}
                                        </Td>
                                      ))}
                                </Tr>
                              ))}
                            </Tbody>
                          </Table>
                        </TableContainer>
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              );
            })}
          </Accordion>
        </div>
      </div>
      {projectExistsWarning && (
        <div className={styles.page}>
          <AlertDialog isOpen={projectExistsWarning}>
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader>Metódo já existe</AlertDialogHeader>
                <AlertDialogCloseButton
                  onClick={() => setProjectExistsWarning(false)}
                />
                <AlertDialogBody>
                  O método com esse nome já existe. Escolha um nome diferente.
                </AlertDialogBody>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        </div>
      )}
    </>
  );
}

export default ParametersManager;
