import { extendTheme } from "@chakra-ui/react";

const theme = {
  colors: {
    primary: "blue",
    second: "red",
    white: "#ffffff",
    gray: "#F9F9F9",
    border: "#ffffff",
    selected: "#d9d9d9",
  },
  components: {
    Button: {
      baseStyle: {
        fontFamily: "var(--font-family)",
        fontWeight: "bold",
        borderRadius: "var(--border-radius)",
      },
      sizes: {
        md: {
          fontSize: "var(--font-size-base)",
          px: "50px",
        },
      },
      variants: {
        solid: {
          color: "var(--text-color)",
          _hover: {
            color: "var(--accent-light-color)",
          },
        },
      },
    },
  },
};

export default extendTheme(theme);
