import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as jwt_decode from "jwt-decode";
import { TbLogout, TbUserCircle } from "react-icons/tb";
import { CloseIcon, HamburgerIcon } from "@chakra-ui/icons";

import { api } from "../../../utils/services/api";

import styles from "./Home.module.css";

import calculators from "../utils/data/calculators.json";
import softwares from "../utils/data/softwares.json";
import tutorials from "../utils/data/tutorials.json";
import updates from "../utils/data/updates.json";

import backgroundImage from "../utils/img/sky-background.png";
import homepageImage1 from "../utils/img/homepage-1.png";
import homepageImage2 from "../utils/img/homepage-2.png";

import logo from "../utils/img/ars-logo-sem-fundo.png";

import Calculators from "./home/containers/Calculators";
import Softwares from "./home/containers/Softwares";
import Tutorials from "./home/containers/Tutorials";
import Updates from "./home/containers/Updates";
import Support from "./home/containers/Support";
import Login from "./home/containers/Login";

import ColorModeButton from "./home/components/ColorModeButton";

function Home({ userInputs, updateUserInputs }) {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState("Home");
  const [menuOpen, setMenuOpen] = useState(false);
  const [popupOpen, setPopupOpen] = useState("");
  const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });
  const popupRef = useRef(null);
  const [popupSelected, setPopupSelected] = useState("");

  function handleTabClick(tab) {
    if (menuOpen) {
      setMenuOpen(false);
    }
    setSelectedTab(tab);
  }

  function handleTabEnter(event, tab) {
    const rect = event.target.getBoundingClientRect();
    setPopupPosition({
      top: rect.bottom + window.scrollY,
      left: rect.left + window.scrollX,
    });
    setPopupOpen(tab);
  }

  function handleTabLeave() {
    setPopupOpen("");
  }

  function handlePopupClick(tab, option) {
    setPopupSelected(option);
    handleTabClick(tab);
    setPopupOpen("");
  }

  function getSoftwareNavigate(software) {
    {
      /* Fixed because of database shutdown */
    }
    // if (userInputs["id"] === null) {
    //   setSelectedTab("Login");
    // } else {
    //   if (software === "FundArs") {
    //     navigate("/fundars");
    //   } else if (software === "QuantitArs") {
    //     navigate("/quantitars");
    //   } else {
    //     navigate("complas");
    //   }
    // }
    navigate(softwares[software]["navigate"]);
  }

  function responseGoogle(response) {
    const user_info = jwt_decode.jwtDecode(response.credential);
    api.post("/user", user_info).then((res) => {
      updateUserInputs("id", res["data"]);
      updateUserInputs("name", user_info["name"]);
      window.localStorage.setItem("user_id", res["data"]);
      window.localStorage.setItem("user_name", user_info["name"]);
      setSelectedTab("Produtos");
    });
  }

  function logout() {
    updateUserInputs("id", null);
    updateUserInputs("name", "");
    window.localStorage.removeItem("user_id");
    window.localStorage.removeItem("user_name");
  }

  return (
    <div className={styles.bodyHome}>
      {/* Header */}
      <header className={styles.header}>
        <div className={styles.headerLeft}>
          <div className={styles.logo} onClick={() => handleTabClick("Home")}>
            <img className={styles.logoImage} src={logo}></img>
            <h1>
              Engenh<span>Ars</span>
            </h1>
          </div>
          <div className={`${menuOpen ? styles.tabsOpen : styles.tabs}`}>
            <button
              className={
                selectedTab === "Calculators"
                  ? `${styles.tab} ${styles.selected}`
                  : styles.tab
              }
              onClick={() => handleTabClick("Calculators")}
            >
              Calculadoras
            </button>
            <button
              className={
                selectedTab === "Softwares"
                  ? `${styles.tab} ${styles.selected}`
                  : styles.tab
              }
              onClick={() => handleTabClick("Softwares")}
            >
              Programas
            </button>
            <button
              className={
                selectedTab === "Tutorials"
                  ? `${styles.tab} ${styles.selected}`
                  : styles.tab
              }
              onClick={(e) => handleTabEnter(e, "Tutorials")}
              onMouseLeave={handleTabLeave}
            >
              Tutoriais
              {popupOpen === "Tutorials" && (
                <div
                  ref={popupRef}
                  className={styles.popup}
                  style={{ top: popupPosition.top, left: popupPosition.left }}
                >
                  <ul>
                    {Object.keys(tutorials).map((item, index) => (
                      <li
                        key={index}
                        onClick={() => handlePopupClick("Tutorials", item)}
                      >
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </button>
            <button
              className={
                selectedTab === "Updates"
                  ? `${styles.tab} ${styles.selected}`
                  : styles.tab
              }
              onClick={(e) => handleTabEnter(e, "Updates")}
              onMouseLeave={handleTabLeave}
            >
              Atualizações
              {popupOpen === "Updates" && (
                <div
                  ref={popupRef}
                  className={styles.popup}
                  style={{ top: popupPosition.top, left: popupPosition.left }}
                >
                  <ul>
                    {Object.keys(updates).map((item, index) => (
                      <li
                        key={index}
                        onClick={() => handlePopupClick("Updates", item)}
                      >
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </button>
            <button
              className={
                selectedTab === "Support"
                  ? `${styles.tab} ${styles.selected}`
                  : styles.tab
              }
              onClick={() => handleTabClick("Support")}
            >
              Sugestões/Suporte
            </button>
          </div>
        </div>
        <div className={styles.headerRight}>
          {/* Fixed because of database shutdown */}
          {/* {userInputs["id"] !== null ? (
            <div className={styles.tabUser}>
              <TbUserCircle size={30} />
              <span>{userInputs["name"]}</span>
              <button className={styles.tabUserLogout} onClick={logout}>
                <TbLogout size={30} />
              </button>
            </div>
          ) : (
            <button
              className={styles.tabLogin}
              onClick={() => handleTabClick("Login")}
            >
              Entrar
            </button>
          )} */}
          <button
            className={styles.mobileMenu}
            onClick={() => setMenuOpen(!menuOpen)}
          >
            {menuOpen ? <CloseIcon /> : <HamburgerIcon />}
          </button>
          <ColorModeButton />
        </div>
      </header>
      {/* Section */}
      <main
        className={`${styles.mainHome} ${menuOpen ? styles.hideMain : styles.mainHome}`}
      >
        {selectedTab === "Home" && (
          <>
            <div
              className={styles.section}
              // style={{
              //   backgroundImage: `url(${backgroundImage})`,
              //   backgroundSize: "100% auto", // Estica a imagem para 100% da largura, mantendo a proporção
              //   backgroundPosition: "center", // Centraliza a imagem verticalmente
              //   backgroundRepeat: "no-repeat", // Evita que a imagem se repita
              // }}
            >
              <div className={styles.containerHeader}>
                <h1>
                  Soluções <strong>gratuitas</strong> para{" "}
                  <strong>Engenharia Civil</strong> na palma da sua{" "}
                  <strong>mão</strong>
                </h1>
                <p>
                  Sem precisar instalar nada utilize nossas ferramentas para
                  resolver problemas do seu dia a dia, confira alguns dos
                  benefícios das nossas ferramentas
                </p>
                <div className={styles.headerCards}>
                  <div className={styles.headerCard}>
                    Acesso online facilitado
                  </div>
                  <div className={styles.headerCard}>
                    Interface agradável e intuitiva
                  </div>
                  <div className={styles.headerCard}>Ferramentas modernas</div>
                  <div className={styles.headerCard}>
                    Constantemente incrementada
                  </div>
                  <div className={styles.headerCard}>
                    Exportação de tabelas e relatórios
                  </div>
                </div>
              </div>
            </div>
            <div
              className={styles.section}
              style={{
                backgroundColor: "var(--background-dark-color)",
              }}
            >
              <div className={styles.containerCalculators}>
                <div className={styles.calculatorsText}>
                  <h1>Calculadoras</h1>
                  <p>
                    Faça verificações rápidas com uma interface simplificada e
                    direcionada para o seu problema
                  </p>
                  <button
                    className={styles.homepageButton}
                    onClick={() => handleTabClick("Calculators")}
                  >
                    SAIBA MAIS
                  </button>
                </div>
                <img
                  className={styles.calculatorsImage}
                  src={homepageImage1}
                ></img>
              </div>
            </div>
            <div className={styles.section}>
              <div className={styles.containerSoftwares}>
                <div className={styles.softwaresText}>
                  <h1>Programas</h1>
                  <p>
                    Soluções completas para resolução de problemas maiores, com
                    integração do começo ao fim da solução
                  </p>
                  <button
                    className={styles.homepageButton}
                    onClick={() => handleTabClick("Softwares")}
                  >
                    SAIBA MAIS
                  </button>
                </div>
                <img
                  className={styles.softwaresImage}
                  src={homepageImage2}
                ></img>
              </div>
            </div>
            <div
              className={styles.section}
              style={{ backgroundColor: "var(--background-dark-color)" }}
            >
              <div className={styles.containerOthers}>
                <div className={styles.othersText}>
                  <h1>Guia básico</h1>
                  <p>Está com dificuldade em utilizar as nossas ferramentas?</p>
                  <p>
                    Pensando nisso desenvolvemos um tutorial completo para cada
                    ferramenta
                  </p>
                </div>
                <div className={styles.othersText}>
                  <h1>Transparência</h1>
                  <p>
                    Acompanhe o que está sendo desenvolvido pela nossa equipe
                    desde melhorias para as nossas ferramentas até novas
                    ferramentas que serão adicioandas!
                  </p>
                </div>
                <div className={styles.othersText}>
                  <h1>Participe do desenvovimento</h1>
                  <p>
                    Possui alguma sugestão de melhoria ou gostaria de reportar
                    algum erro que encontrou?
                  </p>
                  <p>Estamos abertos a todo e qualquer tipo de feedback</p>
                </div>
              </div>
            </div>
          </>
        )}
        {selectedTab === "Calculators" && (
          <Calculators
            calculators={calculators}
            getSoftwareNavigate={getSoftwareNavigate}
          />
        )}
        {selectedTab === "Softwares" && (
          <Softwares
            softwares={softwares}
            getSoftwareNavigate={getSoftwareNavigate}
          />
        )}
        {selectedTab === "Tutorials" && (
          <Tutorials tutorials={tutorials} popupSelected={popupSelected} />
        )}
        {selectedTab === "Updates" && (
          <Updates updates={updates} popupSelected={popupSelected} />
        )}
        {selectedTab === "Support" && <Support />}
        {selectedTab === "Login" && <Login responseGoogle={responseGoogle} />}
      </main>
      {/* Footer */}
      <footer className={styles.footer}>
        <a
          href="https://www.linkedin.com/in/felipe-dieguez-378473212/"
          target="_blank"
        >
          <i className="fab fa-linkedin"></i>
        </a>
        <a href="https://www.instagram.com/felipedieguezz/" target="_blank">
          <i className="fab fa-instagram"></i>
        </a>
        <a href="https://github.com/FelipeDieguez" target="_blank">
          <i className="fab fa-github"></i>
        </a>
      </footer>
    </div>
  );
}

export default Home;
