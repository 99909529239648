import { useState } from "react";

import styles from "./Structure.module.css";

import ColumnsManager from "../components/structure/ColumnsManager";
import SolutionsManager from "../components/structure/SolutionsManager";
import FoundationsSolution from "../components/structure/FoundationsSolution";
import FoundationsManager from "../components/structure/FoundationsManager";

function Structure({
  userInputs,
  projectInputs,
  foundationClass,
  setFoundationClass,
  geotechnicsInputs,
  structureInputs,
  geotechnicsData,
  updateGeotechnicsInputs,
  updateStructureInputs,
}) {
  const [columnInputs, setColumnInputs] = useState({
    selected_name: "",
    Nome: "",
    Nd: 0,
    Mx: 0,
    My: 0,
  });
  const [columnsData, setColumnsData] = useState([]);
  const [columnResults, setColumnResults] = useState({
    Nome: "",
    Nd: 0,
    Mx: 0,
    My: 0,
  });

  function onColumnResultsChange(columnName, columns) {
    setColumnResults((prevInputs) => ({ ...prevInputs, ["Nome"]: columnName }));
    const results = columns.find((obj) => obj.Nome === columnName);
    setColumnResults((prevInputs) => ({
      ...prevInputs,
      ["Nd"]: results["Nd"],
    }));
    setColumnResults((prevInputs) => ({
      ...prevInputs,
      ["Mx"]: results["Mx"],
    }));
    setColumnResults((prevInputs) => ({
      ...prevInputs,
      ["My"]: results["My"],
    }));
  }

  return (
    <div className={styles.grid}>
      <div className={styles.firstTitle}>
        <strong>ESTRUTURAS</strong>
      </div>
      <div className={styles.containerColumn} style={{ width: "100%" }}>
        <ColumnsManager
          userInputs={userInputs}
          projectInputs={projectInputs}
          columnInputs={columnInputs}
          setColumnInputs={setColumnInputs}
          columnsData={columnsData}
          setColumnsData={setColumnsData}
          columnResults={columnResults}
          onColumnResultsChange={onColumnResultsChange}
        />
      </div>
      {/* <div className={styles.containerColumn}>
                <SolutionsManager
                    userInputs={userInputs}
                    projectInputs={projectInputs}
                />
            </div> */}
      <div
        className={styles.containerColumn}
        style={{ height: "60%", width: "100%" }}
      >
        <FoundationsSolution
          foundationClass={foundationClass}
          setFoundationClass={setFoundationClass}
          geotechnicsInputs={geotechnicsInputs}
          geotechnicsData={geotechnicsData}
          updateGeotechnicsInputs={updateGeotechnicsInputs}
          columnResults={columnResults}
        />
      </div>
      {/* <div className={styles.containerColumn} style={{ height: '25%' }}>
                <FoundationsManager/>
            </div> */}
    </div>
  );
}

export default Structure;
