import { useState } from "react";

import {
  TabList,
  Tabs,
  Tab,
  Input,
  Text,
  Select,
  Button,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from "@chakra-ui/react";

import styles from "../../containers/Structure.module.css";

import foundationTypes from "../../../../utils/data/foundationTypes.json";
import concreteTypes from "../../../../utils/data/concreteTypes.json";
import rebarTypes from "../../../../utils/data/rebarTypes.json";
import rebarAreas from "../../../../utils/data/rebarAreas.json";
import pileCapTypes from "../../../../utils/data/pileCapTypes.json";
import geotechnicsMethods from "../../../../utils/data/geotechnicsMethods.json";

import {
  pileLengthCalculate,
  pileCapCalculate,
  pileCalculate,
} from "../../../../utils/services/structure";

function FoundationsSolution({
  foundationClass,
  setFoundationClass,
  geotechnicsInputs,
  geotechnicsData,
  updateGeotechnicsInputs,
  columnResults,
}) {
  const [columnStresses, setColumnStresses] = useState({
    Nome: "",
    Nd: 0,
    Mx: 0,
    My: 0,
  });
  const [pileCapInputs, setPileCapInputs] = useState({
    l: 0,
    n: 1,
    fck: 20,
    fyk: 50,
    c: 0,
    ap: 0,
    bp: 0,
    filong: 10,
    z: 0,
  });
  const [pileCapResults, setPileCapResults] = useState({
    0: {
      teta: 0,
      ctc: "",
      C: 0,
      B: 0,
      H: 0,
      as: 0,
      assup: 0,
      aspele: 0,
      asw: 0,
    },
  });
  const [zList, setZList] = useState([]);
  const [pileCapRebarInputs, setPileCapRebarInputs] = useState({
    as_qtt: 1,
    fias: 6.3,
    assup_qtt: 1,
    fiassup: 6.3,
    aspele_qtt: 1,
    fiaspele: 6.3,
    asw_esp: 10,
    fiasw: 6.3,
  });
  // const [pileCapRebarResults, setPileCapRebarResults] = useState({'asadot': 0, 'assupadot': 0, 'aspeleadot': 0, 'aswadot': 0})
  const [pileInputs, setPileInputs] = useState({
    length: 1,
    fck: 20,
    fyk: 50,
    c: 0,
  });
  const [pileResults, setPileResults] = useState({
    clength: 0,
    tlength: 0,
    rc: 0,
    asmin: 0,
    asc: 0,
    rt: 0,
    ast: 0,
  });
  const [pileRebarInputs, setPileRebarInputs] = useState({
    as_qtt: 1,
    fias: 6.3,
    arm_length: 1,
  });
  // const [pileRebarResults, setPileRebarResults] = useState({'asadot': 0, 'aswadot': 0})

  function onPileCapInputsChange(ev) {
    const { name, value } = ev.target;
    setPileCapInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
  }

  function onPileCapRebarInputsChange(ev) {
    const { name, value } = ev.target;
    setPileCapRebarInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
  }

  function onPileInputsChange(ev) {
    const { name, value } = ev.target;
    setPileInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
  }

  function onPileRebarInputsChange(ev) {
    const { name, value } = ev.target;
    setPileRebarInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
  }

  function onPileLengthResultsCalculate() {
    pileLengthCalculate([geotechnicsData, columnResults, pileCapInputs]).then(
      (response) => {
        setPileResults(response["data"]);
      }
    );
  }

  function onPileCapResultsCalculate() {
    pileCapCalculate([geotechnicsInputs, columnResults, pileCapInputs]).then(
      (response) => {
        // console.log(response['data'], Object.keys(response['data']), parseInt(Object.keys(response['data'])[0]))
        setPileCapInputs((prevInputs) => ({
          ...prevInputs,
          ["z"]: Object.keys(response["data"])[0],
        }));
        setPileCapResults(response["data"]);
        setZList(Object.keys(response["data"]));
      }
    );
  }

  function onPileResultsCalculate() {
    pileCalculate([
      geotechnicsInputs,
      pileCapInputs,
      pileInputs,
      pileResults,
    ]).then((response) => {
      setPileResults(response["data"]);
      console.log(response["data"]);
    });
  }

  function onFoundationClassChange(ev) {
    const foundation_type = ev.target.id;
    setFoundationClass(foundation_type);
    updateGeotechnicsInputs("metodo", geotechnicsMethods[foundation_type]);
    updateGeotechnicsInputs("tipo", foundationTypes[foundation_type][0]);
    updateGeotechnicsInputs("esforco", "compressao");
  }

  return (
    <>
      <div className={styles.secondTitle}>FUNDAÇÕES:</div>
      <div className={styles.nav}>
        <Tabs size="sm" variant="enclosed">
          <TabList>
            <Tab
              name="estacas"
              // onClick={}
              fontSize="md"
            >
              ESTACAS
            </Tab>
            <Tab
              name="sapatas"
              // onClick={}
              fontSize="md"
              isDisabled
            >
              SAPATAS
            </Tab>
            <Tab
              name="tubuloes"
              // onClick={}s
              fontSize="md"
              isDisabled
            >
              TUBULÃO
            </Tab>
          </TabList>
        </Tabs>
      </div>
      <div className={styles.section}>
        <div className={styles.containerRow}>
          <div
            className={styles.containerColumn}
            style={{ margin: "0px 10px 0px 0px" }}
          >
            <div className={styles.stepRow}>
              <div className={styles.secondTitle}>ESFORÇOS:</div>
            </div>
            <div className={styles.stepRow}>
              <Text fontSize="md">Nd= {columnResults["Nd"]} kN</Text>
              <Text fontSize="md">Mx= {columnResults["Mx"]} kNm</Text>
              <Text fontSize="md">My= {columnResults["My"]} kNm</Text>
            </div>
            <div className={styles.stepRow}>
              <div className={styles.step}>
                <Text fontSize="md">L(m)=</Text>
                <Input
                  name="l"
                  onKeyPress={(event) => {
                    if (!/[0-9.]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onChange={onPileCapInputsChange}
                  width="50px"
                  size="xs"
                  fontSize="md"
                />
              </div>
              <div className={styles.step}>
                <Text fontSize="md">Nº de estacas=</Text>
                <Select
                  name="n"
                  onChange={onPileCapInputsChange}
                  variant="outline"
                  w="60px"
                  size="xs"
                  fontSize="md"
                  value={pileCapInputs["n"]}
                >
                  {pileCapTypes["pileQuantities"].map((name, i) => (
                    <option key={i} value={name}>
                      {" "}
                      {name}{" "}
                    </option>
                  ))}
                </Select>
              </div>
              <Button
                name="calculate_1"
                width="120px"
                onClick={onPileLengthResultsCalculate}
                colorScheme="blue"
                size="sm"
                fontSize="sm"
              >
                Calcular Comprimento
              </Button>
            </div>
            <div className={styles.stepRow}>
              <Text fontSize="md">Compressão= {pileResults["clength"]} m</Text>
              <Text fontSize="md">Tração= {pileResults["tlength"]} m</Text>
              <div className={styles.step}>
                <Text fontSize="md">Adot.(m)=</Text>
                <Select
                  name="length"
                  onChange={onPileInputsChange}
                  variant="outline"
                  w="60px"
                  size="xs"
                  fontSize="md"
                  value={pileInputs["length"]}
                >
                  {[...Array(geotechnicsData.length).keys()].map((element) => (
                    <option key={element + 1}>{element + 1}</option>
                  ))}
                </Select>
              </div>
            </div>
            <div className={styles.stepRow}>
              <div className={styles.secondTitle}>BLOCO:</div>
            </div>
            <div className={styles.stepRow}>
              <div className={styles.step}>
                <Text fontSize="md">fck(MPa)=</Text>
                <Select
                  name="fck"
                  onChange={onPileCapInputsChange}
                  variant="outline"
                  w="60px"
                  size="xs"
                  fontSize="md"
                  value={pileCapInputs["fck"]}
                >
                  {concreteTypes["fck"].map((name, i) => (
                    <option key={i} value={name}>
                      {" "}
                      {name}{" "}
                    </option>
                  ))}
                </Select>
              </div>
              <div className={styles.step}>
                <Text fontSize="md">fyk(MPa)=</Text>
                <Select
                  name="fyk"
                  onChange={onPileCapInputsChange}
                  variant="outline"
                  w="60px"
                  size="xs"
                  fontSize="md"
                  value={pileCapInputs["fyk"]}
                >
                  {Object.keys(rebarTypes).map((name, i) => (
                    <option key={i} value={name}>
                      {" "}
                      {name}{" "}
                    </option>
                  ))}
                </Select>
              </div>
              <div className={styles.step}>
                <Text fontSize="md">c(cm)=</Text>
                <Input
                  name="c"
                  onKeyPress={(event) => {
                    if (!/[0-9.]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onChange={onPileCapInputsChange}
                  width="50px"
                  size="xs"
                  fontSize="md"
                />
              </div>
            </div>
            <div className={styles.stepRow}>
              <div className={styles.step}>
                <Text fontSize="md">Ap(cm)=</Text>
                <Input
                  name="ap"
                  onKeyPress={(event) => {
                    if (!/[0-9.]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onChange={onPileCapInputsChange}
                  width="50px"
                  size="xs"
                  fontSize="md"
                />
              </div>
              <div className={styles.step}>
                <Text fontSize="md">Bp(cm)=</Text>
                <Input
                  name="bp"
                  onKeyPress={(event) => {
                    if (!/[0-9.]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onChange={onPileCapInputsChange}
                  width="50px"
                  size="xs"
                  fontSize="md"
                />
              </div>
              <div className={styles.step}>
                <Text fontSize="md">Ølong(mm)=</Text>
                <Select
                  name="filong"
                  onChange={onPileCapInputsChange}
                  variant="outline"
                  w="60px"
                  size="xs"
                  fontSize="md"
                  value={pileCapInputs["filong"]}
                >
                  {rebarTypes[pileCapInputs["fyk"]].map((name, i) => (
                    <option key={i} value={name}>
                      {" "}
                      {name}{" "}
                    </option>
                  ))}
                </Select>
              </div>
            </div>
            <div
              className={styles.stepRow}
              style={{ justifyContent: "flex-end" }}
            >
              <Button
                name="calculate_2"
                width="120px"
                onClick={onPileCapResultsCalculate}
                colorScheme="blue"
                size="sm"
                fontSize="sm"
              >
                Calcular Bielas
              </Button>
            </div>
            <div className={styles.stepRow}>
              <div className={styles.step}>
                <Text fontSize="md">z(cm)=</Text>
                <Select
                  name="z"
                  onChange={onPileCapInputsChange}
                  variant="outline"
                  w="60px"
                  size="xs"
                  fontSize="md"
                  value={pileCapInputs["z"]}
                >
                  {zList.map((name, i) => (
                    <option key={i} value={name}>
                      {" "}
                      {name}{" "}
                    </option>
                  ))}
                </Select>
              </div>
              <Text fontSize="md">
                θ= {pileCapResults[pileCapInputs["z"]]["teta"].toFixed(1)}º
              </Text>
              <Text fontSize="md">
                CTC: {pileCapResults[pileCapInputs["z"]]["ctc"]}
              </Text>
            </div>
            <div className={styles.stepRow}>
              <Text fontSize="md">
                C= {pileCapResults[pileCapInputs["z"]]["C"]} cm
              </Text>
              <Text fontSize="md">
                B= {pileCapResults[pileCapInputs["z"]]["B"]} cm
              </Text>
              <Text fontSize="md">
                H= {pileCapResults[pileCapInputs["z"]]["H"]} cm
              </Text>
            </div>
            <div className={styles.stepRow}>
              <div className={styles.secondTitle}>ARMADURAS:</div>
            </div>
            <div className={styles.stepRow}>
              <Text fontSize="md">
                As= {pileCapResults[pileCapInputs["z"]]["as"].toFixed(2)} cm²
              </Text>
              <div className={styles.step}>
                <NumberInput
                  size="xs"
                  width="60px"
                  defaultValue={1}
                  min={1}
                  max={99}
                  precision={0}
                  step={1}
                  onChange={(ev) => {
                    {
                      setPileCapRebarInputs((prevInputs) => ({
                        ...prevInputs,
                        ["as_qtt"]: ev,
                      }));
                    }
                  }}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <Text fontSize="md">Ø</Text>
                <Select
                  name="fias"
                  onChange={onPileCapRebarInputsChange}
                  variant="outline"
                  w="70px"
                  size="xs"
                  fontSize="md"
                  value={pileCapRebarInputs["fias"]}
                >
                  {rebarTypes[pileCapInputs["fyk"]].map((name, i) => (
                    <option key={i} value={name}>
                      {" "}
                      {name}{" "}
                    </option>
                  ))}
                </Select>
                <Text fontSize="md">
                  mm (
                  {(
                    pileCapRebarInputs["as_qtt"] *
                    rebarAreas[pileCapRebarInputs["fias"]]
                  ).toFixed(2)}{" "}
                  cm²)
                </Text>
              </div>
            </div>
            <div className={styles.stepRow}>
              <Text fontSize="md">
                As,sup= {pileCapResults[pileCapInputs["z"]]["assup"].toFixed(2)}{" "}
                cm²
              </Text>
              <div className={styles.step}>
                <NumberInput
                  size="xs"
                  width="60px"
                  defaultValue={1}
                  min={1}
                  max={99}
                  precision={0}
                  step={1}
                  onChange={(ev) => {
                    {
                      setPileCapRebarInputs((prevInputs) => ({
                        ...prevInputs,
                        ["assup_qtt"]: ev,
                      }));
                    }
                  }}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <Text fontSize="md">Ø</Text>
                <Select
                  name="fiassup"
                  onChange={onPileCapRebarInputsChange}
                  variant="outline"
                  w="70px"
                  size="xs"
                  fontSize="md"
                  value={pileCapRebarInputs["fiassup"]}
                >
                  {rebarTypes[pileCapInputs["fyk"]].map((name, i) => (
                    <option key={i} value={name}>
                      {" "}
                      {name}{" "}
                    </option>
                  ))}
                </Select>
                <Text fontSize="md">
                  mm (
                  {(
                    pileCapRebarInputs["assup_qtt"] *
                    rebarAreas[pileCapRebarInputs["fiassup"]]
                  ).toFixed(2)}{" "}
                  cm²)
                </Text>
              </div>
            </div>
            <div className={styles.stepRow}>
              <Text fontSize="md">
                As,pele={" "}
                {pileCapResults[pileCapInputs["z"]]["aspele"].toFixed(2)} cm²
              </Text>
              <div className={styles.step}>
                <NumberInput
                  size="xs"
                  width="60px"
                  defaultValue={1}
                  min={1}
                  max={99}
                  precision={0}
                  step={1}
                  onChange={(ev) => {
                    {
                      setPileCapRebarInputs((prevInputs) => ({
                        ...prevInputs,
                        ["aspele_qtt"]: ev,
                      }));
                    }
                  }}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <Text fontSize="md">Ø</Text>
                <Select
                  name="fiaspele"
                  onChange={onPileCapRebarInputsChange}
                  variant="outline"
                  w="70px"
                  size="xs"
                  fontSize="md"
                  value={pileCapRebarInputs["fiaspele"]}
                >
                  {rebarTypes[pileCapInputs["fyk"]].map((name, i) => (
                    <option key={i} value={name}>
                      {" "}
                      {name}{" "}
                    </option>
                  ))}
                </Select>
                <Text fontSize="md">
                  mm (
                  {(
                    pileCapRebarInputs["aspele_qtt"] *
                    rebarAreas[pileCapRebarInputs["fiaspele"]]
                  ).toFixed(2)}{" "}
                  cm²)
                </Text>
              </div>
            </div>
            <div className={styles.stepRow} style={{ padding: "0px" }}>
              <Text fontSize="md">
                Asw= {pileCapResults[pileCapInputs["z"]]["asw"].toFixed(2)}{" "}
                cm²/m
              </Text>
              <div className={styles.step}>
                <Text fontSize="md">Ø</Text>
                <Select
                  name="fiasw"
                  onChange={onPileCapRebarInputsChange}
                  variant="outline"
                  w="70px"
                  size="xs"
                  fontSize="md"
                  value={pileCapRebarInputs["fiasw"]}
                >
                  {rebarTypes[pileCapInputs["fyk"]].map((name, i) => (
                    <option key={i} value={name}>
                      {" "}
                      {name}{" "}
                    </option>
                  ))}
                </Select>
                <Text fontSize="md">mm c/</Text>
                <NumberInput
                  size="xs"
                  width="60px"
                  defaultValue={10}
                  min={1}
                  max={99}
                  precision={0}
                  step={1}
                  onChange={(ev) => {
                    {
                      setPileCapRebarInputs((prevInputs) => ({
                        ...prevInputs,
                        ["asw_esp"]: ev,
                      }));
                    }
                  }}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <Text fontSize="md">
                  cm (
                  {(
                    (rebarAreas[pileCapRebarInputs["fiasw"]] * 100) /
                    pileCapRebarInputs["asw_esp"]
                  ).toFixed(2)}{" "}
                  cm²/m)
                </Text>
              </div>
            </div>
          </div>
          <div className={styles.containerColumn}>
            <div className={styles.stepRow}>
              <div className={styles.secondTitle}>ESTACA:</div>
            </div>
            <div className={styles.stepRow}>
              <div className={styles.step}>
                <Text fontSize="md">fck(MPa)=</Text>
                <Select
                  name="fck"
                  onChange={onPileInputsChange}
                  variant="outline"
                  w="60px"
                  size="xs"
                  fontSize="md"
                  value={pileInputs["fck"]}
                >
                  {concreteTypes["fck"].map((name, i) => (
                    <option key={i} value={name}>
                      {" "}
                      {name}{" "}
                    </option>
                  ))}
                </Select>
              </div>
              <div className={styles.step}>
                <Text fontSize="md">fyk(MPa)=</Text>
                <Select
                  name="fyk"
                  onChange={onPileInputsChange}
                  variant="outline"
                  w="60px"
                  size="xs"
                  fontSize="md"
                  value={pileInputs["fyk"]}
                >
                  {Object.keys(rebarTypes).map((name, i) => (
                    <option key={i} value={name}>
                      {" "}
                      {name}{" "}
                    </option>
                  ))}
                </Select>
              </div>
              <div className={styles.step}>
                <Text fontSize="md">c(cm)=</Text>
                <Input
                  name="c"
                  onKeyPress={(event) => {
                    if (!/[0-9.]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onChange={onPileInputsChange}
                  width="50px"
                  size="xs"
                  fontSize="md"
                />
              </div>
            </div>
            <div
              className={styles.stepRow}
              style={{ justifyContent: "flex-end" }}
            >
              <Button
                name="calculate_3"
                width="120px"
                onClick={onPileResultsCalculate}
                colorScheme="blue"
                size="sm"
                fontSize="sm"
              >
                Calcular
              </Button>
            </div>
            <div className={styles.stepRow}>
              <div className={styles.secondTitle}>COMPRESSÃO:</div>
            </div>
            <div className={styles.stepRow}>
              <Text fontSize="md">Rc= {pileResults["rc"].toFixed(2)} kN</Text>
              <Text fontSize="md">
                As,min= {pileResults["asmin"].toFixed(2)} cm²
              </Text>
              <Text fontSize="md">As= {pileResults["asc"].toFixed(2)} cm²</Text>
            </div>
            <div className={styles.stepRow}>
              <div className={styles.secondTitle}>TRAÇÃO:</div>
            </div>
            <div className={styles.stepRow}>
              <Text fontSize="md">Rt= {pileResults["rt"].toFixed(2)} kN</Text>
              <Text fontSize="md">As= {pileResults["ast"].toFixed(2)} cm²</Text>
            </div>
            <div className={styles.stepRow}>
              <div className={styles.secondTitle}>ARMADURA LONGITUDINAL:</div>
            </div>
            <div className={styles.stepRow}>
              <div className={styles.step}>
                <NumberInput
                  size="xs"
                  width="60px"
                  defaultValue={1}
                  min={1}
                  max={99}
                  precision={0}
                  step={1}
                  onChange={(ev) => {
                    {
                      setPileRebarInputs((prevInputs) => ({
                        ...prevInputs,
                        ["as_qtt"]: ev,
                      }));
                    }
                  }}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <Text fontSize="md">Ø</Text>
                <Select
                  name="fias"
                  onChange={onPileRebarInputsChange}
                  variant="outline"
                  w="70px"
                  size="xs"
                  fontSize="md"
                  value={pileRebarInputs["fias"]}
                >
                  {rebarTypes[pileInputs["fyk"]].map((name, i) => (
                    <option key={i} value={name}>
                      {" "}
                      {name}{" "}
                    </option>
                  ))}
                </Select>
                <Text fontSize="md">
                  mm (
                  {(
                    pileRebarInputs["as_qtt"] *
                    rebarAreas[pileRebarInputs["fias"]]
                  ).toFixed(2)}{" "}
                  cm²)
                </Text>
              </div>
            </div>
            <div className={styles.stepRow}>
              <div className={styles.secondTitle}>ARMADURA TRANSVERSAL:</div>
            </div>
            <div className={styles.stepRow}>
              <Text fontSize="md">Ø5.0mm c/30cm</Text>
            </div>
            <div className={styles.stepRow}>
              <div className={styles.step}>
                <Text fontSize="md">Comp. (m)= </Text>
                <Select
                  name="comprimento_armadura"
                  onChange={onPileRebarInputsChange}
                  variant="outline"
                  w="60px"
                  size="xs"
                  fontSize="md"
                  value={pileRebarInputs["arm_length"]}
                >
                  {[...Array(geotechnicsData.length).keys()].map((element) => (
                    <option key={element + 1}>{element + 1}</option>
                  ))}
                </Select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FoundationsSolution;
