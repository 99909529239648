import { Button, Flex, Text, useToast } from "@chakra-ui/react";
import { ArrowUpIcon } from "@chakra-ui/icons";

import { api } from "../../../../../utils/services/api";

import styles from "../../Home.module.css";

function IfcModel({ ifcFileName, setIfcFileName, setIfcData, handleNext }) {
  const toast = useToast();

  async function uploadFile(e) {
    const file = e.target.files[0];
    if (file != null) {
      const loadingToastId = toast({
        title: "Carregando informações do .ifc",
        description: "Por favor, aguarde...",
        status: "info",
        duration: null, // Mantém o toast até que seja fechado manualmente ou atualizado
        isClosable: false, // Não permitir que o usuário feche o toast
      });

      const data = new FormData();
      data.append("file_from_react", file);

      try {
        const response = await api.post("/ifc", data);
        // Atualizar o toast com uma mensagem de sucesso
        toast.update(loadingToastId, {
          title: "As informações do .ifc foram carregadas com sucesso",
          description: "Avance para a próxima pagina!",
          status: "success",
          duration: 5000, // Duração de 5 segundos
          isClosable: true, // Permitir que o usuário feche o toast
        });
        // Atualizar o estado com os dados recebidos
        setIfcFileName(file.name);
        setIfcData(response.data);
        handleNext();
      } catch (error) {
        // Se ocorrer um erro, mostrar um toast de erro
        toast.update(loadingToastId, {
          title: "Erro ao carregar informações",
          description: "Por favor, tente novamente mais tarde.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        console.error("Erro ao carregar informações:", error);
      }
    }
  }

  function getLink() {
    api.get("/ifc/model").then((response) => {
      const url = response.data;
      window.open(url);
    });
  }

  return (
    <Flex align="center" justify="center" h="100%">
      <Flex direction="column" align="center">
        <Text>
          Caso não tenha um arquivo .ifc, utilize esse{" "}
          <span
            onClick={getLink}
            style={{
              color: "#1E88E5",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            arquivo
          </span>{" "}
          para experimentar
        </Text>
        <input
          className={styles.fileInput}
          type="file"
          accept=".ifc"
          id="selectedFile"
          onChange={uploadFile}
        />
        <Button
          onClick={() => document.getElementById("selectedFile").click()}
          colorScheme="blue"
          variant="outline"
          flexDirection="row"
        >
          <ArrowUpIcon marginRight="10px" />
          <Text>Carregar arquivo .ifc</Text>
        </Button>
        <Text color="black">{ifcFileName}</Text>
      </Flex>
    </Flex>
  );
}

export default IfcModel;

// async function onClick() {
//   const file = fileInputRef.current.files[0];
//   if (!file) {
//     console.error("Nenhum arquivo selecionado.");
//     return;
//   }

//   const reader = new FileReader();

//   reader.onload = async (event) => {
//     const ifcData = event.target.result;
//     console.log(ifcData)
//     const ifcUInt8Array = new Uint8Array(ifcData);
//     console.log(ifcUInt8Array)

//     // const WebIFC = require("web-ifc/web-ifc-api.js");
//     const ifcApi = new WebIFC.IfcAPI();

//     try {
//       await ifcApi.Init();
//       let modelID = ifcApi.OpenModel(ifcUInt8Array /* optional settings object */);

//       // Faça o que precisar com o modelo carregado

//       ifcApi.CloseModel(modelID);
//     } catch (error) {
//       console.error("Erro ao inicializar a API IfcAPI:", error);
//     }
//   };

//   reader.readAsArrayBuffer(file);
// }
